import { CircularProgress } from "@mui/material";
import {
  WrapperOverlay,
  WrapperOverlayContent,
  WrapperOverlayInner,
} from "./styles";

export const LoaderOverlay = () => (
  <WrapperOverlay>
    <WrapperOverlayInner>
      <WrapperOverlayContent>
        <CircularProgress size={100} />
      </WrapperOverlayContent>
    </WrapperOverlayInner>
  </WrapperOverlay>
);
