import { styled } from "@mui/system"

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1",
  justifyContent: "center",
  alignItems: "center",
  padding: "5rem",
  border: "2px solid #202020",
  userSelect: "none",
  [theme.breakpoints.down("sm")]:{
    padding: "0rem",
    "img": {
      width: "144px",
      height: "auto",
    }

  }
}))