import { styled } from '@mui/system';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0',
  width: '533px',
  height: '812px',
  isolation: 'isolate',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '327px',
    height: '560px',
  },
}));

export const Media = styled('div')(({ src, theme }) => ({
  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url('${src}')`,
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  width: '100%',
  height: '710px',
  position: 'relative',
  filter: 'drop-shadow(0px 16px 80px rgba(0, 0, 0, 0.5))',
  zIndex: '0',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    height: '400px',
  },
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0 40px',
  gap: '24px',
  position: 'relative',
  width: '100%',
  order: '1',
  zIndex: '1',
  bottom: '-102px',
  '&::after': {
    position: 'relative',
    bottom: '0',
    content: "' '",
    background: 'linear-gradient(90deg, #FF0097 0%, #0069E6 100%)',
    width: '80px',
    height: '3px',
  },
  [theme.breakpoints.down('sm')]: {
    bottom: '-160px',
  },
}));

export const Title = styled('h1')(({ theme }) => ({
  fontSize: '3.75rem',
  lineHeight: '1.5',
  fontWeight: '500',
  margin: '0',
  [theme.breakpoints.down('xl')]: {
    fontSize: '3.25rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '2.8rem',
  },
}));

export const Text = styled('p')(({ theme }) => ({
  fontSize: '1.375rem',
  lineHeight: '1.5',
  fontWeight: '500',
  margin: '0',
  [theme.breakpoints.down('xl')]: {
    fontSize: '1.25rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.1875rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1.125rem',
  },
}));
