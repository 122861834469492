import { Container, PlayerDiv } from './styles';

import ReactPlayer from 'react-player';

export const VideoBanner = ({ url, height }) => {
  return (
    <Container>
      <PlayerDiv>
        <ReactPlayer
          width="100%"
          height={height != null ? height : "41.6875rem"}
          url={url}
          playing={true}
          loop={true}
          muted={true}
        />
      </PlayerDiv>
    </Container>
  );
};
