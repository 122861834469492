import {
  SectionContainer,
  OffersContainer,
  Cell,
  SectionTitle,
} from './styles';

import { Card } from '../../../components/Card';
import { useTranslation } from 'react-i18next';
import { useTranslateGetObject } from '../../../shared/hooks/useTranslateGetObject';

export const Offer = () => {
  const { t } = useTranslation();
  const offers = useTranslateGetObject('offers');

  const offersThatMovesYou = offers.filter(
    (offer) => offer.category === 'offer-that-move-you'
  );
  const offersThatLeverageYou = offers.filter(
    (offer) => offer.category === 'offer-that-leverage-you'
  );
  return (
    <SectionContainer>
      <SectionTitle>{t('home.offer.sectionTitle1')}</SectionTitle>
      <OffersContainer>
        {offersThatMovesYou.sort((a, b) => a.order - b.order).map((offer, index) => (
          <Cell
            key={index}
            span={index === 0 || index === 3 ? 2 : 1}
            spanDirection={index === 3 ? 'horizontal' : 'vertical'}
          >
            <Card
              lines={3}
              title={offer.title}
              content={offer.description}
              image={offer.thumb}
              link={offer.link}
            />
          </Cell>
        ))}
      </OffersContainer>
      <SectionTitle>{t('home.offer.sectionTitle2')}</SectionTitle>
      <OffersContainer>
        {offersThatLeverageYou.sort((a, b) => a.order - b.order).map((offer, index) => (
          <Cell
            key={index}
            span={index === 0 ? 2 : 1}
            spanDirection={index === 0 ? 'horizontal' : 'vertical'}
          >
            <Card
              lines={3}
              direction={index === 0 ? 'row' : 'column'}
              title={offer.title}
              content={offer.description}
              image={offer.thumb}
              link={offer.link}
            />
          </Cell>
        ))}
      </OffersContainer>
    </SectionContainer>
  );
};
