import { styled } from "@mui/system";

export const PageContainer = styled("main")(({ theme }) => ({
  padding: "0 10rem",
  maxWidth: "120rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {
    padding: "0 5rem"
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 1.5rem"
  }
}));

export const StyledVideoDiv = styled("div")(({ theme }) => ({
  paddingBottom: "5rem",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "2.5rem",
  },
}));
