import {
  StyledDiv,
  StyledDivider,
  FooterContainer,
  StyledLinkBar,
  StyledSocialNetworkBar,
} from "./styles";
import { SocialNetworks } from "./socialNetworks";
import { LinkNavigation } from "./linkNavigation";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <FooterContainer>
        <StyledLinkBar>
          <LinkNavigation />
        </StyledLinkBar>
        <StyledDivider />
        <StyledSocialNetworkBar>
          <StyledDiv>
            <StyledDiv id="title-social-network">
              {t("footer.titleSocialNetwork")}
            </StyledDiv>
            <StyledDiv>
              <SocialNetworks />
            </StyledDiv>
            <StyledDiv id="copyright">
              &copy; {new Date().getFullYear()} Labsit
            </StyledDiv>
          </StyledDiv>
        </StyledSocialNetworkBar>
      </FooterContainer>
    </footer>
  );
};
