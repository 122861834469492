import { styled } from "@mui/system";
import { GradientText } from "../../components/GradientText";

export const PageContainer = styled("main")(({ theme }) => ({
  background: "#0f0f0f",
  margin: "0 auto",
  padding: "0 5rem",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1.5rem"
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "120rem",
  },
}));

export const StyledVideoDiv = styled("div")(({ theme }) => ({
  paddingBottom: "5rem",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "2.5rem",
  },
}));

export const AboutQuote = styled(GradientText)(({ theme }) => ({
  textAlign: "center",
  fontSize: "2.8125rem",
  lineHeight: "1.5",
  [theme.breakpoints.down("sm")]: {
    padding: "6rem 0 0 0",
    fontSize: "1.625rem",
  },
}));
