import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const WrapperSection = styled("section")(({ rowReverse }) => ({
  display: "flex",
  flexDirection: rowReverse ? "row-reverse" : "row",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  alignContent: "center",
  backgroundColor: "#1A1A1A",
}));

export const WrapperItem = styled("div")({
  flex: 1,
});

export const WrapperFlexContainer = styled("div")({
  display: "flex",
  flexdirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
});

export const WrapperFlexItens = styled("div")({
  flex: 1,
  flexGrow: 0,
  flexBasis: "auto",
});

export const WrapperButton = styled(Button)(() => ({
  width: "327px",
  height: "48px",
  background: "#FFFFFF",
  borderRadius: "100px",
  padding: "0px 16px",
  color: "#202020",
  fontFamily: "DM Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: "150%",
  "&:hover": {
    background: "#FFFFFFE0",
  },
  span: {
    color: "#FFFFFF",
    fontSize: "1rem",
    lineHeight: "1.5",
  },
}));

export const WrapperDivButton = styled("div")(() => ({
  marginBottom: "80px",
  "@media (max-width: 428px)": {
    textAlign: "center",
  },
}));

export const WrapperImage = styled("div")({
  img: {
    width: "640px",
    height: "880px",
  },
  "@media (max-width: 428px)": {
    textAlign: "center",
    width: "100%",
    img: {
      width: "327px",
      height: "449px",
    },
  },
});

export const WrapperTypography = styled("div")(() => ({
  "@media (max-width: 428px)": {
    h4: {
      fontSize: "26px",
    },
    marginLeft: "24px",
    marginRight: "24px",
  },
}));
