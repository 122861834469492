import { FullContainer } from "../../../components/FullContainer";

export const WorkWithUs = () => {
  const title = "home.workWithUs.title";
  const image = "/images/jan-kim.png";
  const labelButton = "home.workWithUs.labelButton";
  const link = "https://www.linkedin.com/company/labsit.io/jobs/";

  return (
    <FullContainer
      title={title}
      image={image}
      labelButton={labelButton}
      rowReverse={true}
      link={link}
    />
  );
};
