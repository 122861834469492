import { Divider, Box, Container, TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const FooterContainer = styled(Box)({
  background: '#0F0F0F',
  color: '#FFFFFF',
});

export const StyledLinkBar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  justifyContent: 'space-between',
  padding: '80px 5rem 40px',
  maxWidth: '120rem',
  margin: '0 auto',
  div: {
    alignItems: 'flex-start',
  },

  '.element-link': {
    padding: '0 0 20px 0',
  },
  a: {
    fontSize: '18px',
    lineHeight: '150%',
    fontWeight: '500',
    color: '#FFF',
    textDecoration: 'none',
    transition: 'color 300ms',
  },
  'a:hover, & a.active': {
    color: '#FF0097',
  },
  label: {
    fontWeight: '500',
    fontSize: '26px',
    lineHeight: '200%',
    display: 'inline-block',
    border: '2px solid',
    borderImage: 'linear-gradient(90deg, #FF0097 0%, #0069E6 100%) 0 2 100%',
    width: '100%',
    marginBottom: '1rem',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '80px 1.5rem 40px',
    label: {
      fontSize: '16px',
    },
    a: {
      fontSize: '16px',
    },
  },
}));

export const StyledSocialNetworkBar = styled(Container)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  gap: '2.5rem',
  maxWidth: '120rem',
  padding: '0 5rem',
  div: {
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 1.5rem',
  },
  '#title-social-network': {
    fontWeight: '500',
    fontSize: '26px',
    lineHeight: '150%',
    margin: '40px 0 0 0',
  },
  '#copyright': {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '150%',
    margin: '0 0 40px 0',
  },
}));

export const IconsSocialNetworks = styled(Box)(({ theme }) => ({
  margin: '24px 0 24px 0',
  display: 'flex',
  padding: '0px',
  gap: '16px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gap: '8px',
  },
}));

export const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('xl')]: {
    fontSize: '20px',
    '& > label': {
      fontSize: '16px',
    },
  },
}));

export const StyledDivider = styled(Divider)({
  border: '1px solid #3A3A3A',
  color: 'white',
});

export const Row = styled(TableRow)({
  border: 'none',
});

export const OffersGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '2rem',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
