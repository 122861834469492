import {
  Container,
  WrapContainer,
  Title,
  Subtitle,
  FirstParagraph,
  RetanguleParagraph,
  LastParagraph,
  GrayRectangle
} from "./styles";
import { useParams } from "react-router-dom";
import { Banner } from "./Banner";
import { useTranslateGetObject } from "../../shared/hooks/useTranslateGetObject";

export const CareerProfile = () => {
  const { slug } = useParams();
  const humans = useTranslateGetObject("humans");
  const currentHuman = humans?.find((human) => human.slug === slug);
  console.log(currentHuman);

  return (
    <Container>
      <Banner image={currentHuman.image} name={currentHuman.name} job={currentHuman.job} linkedIn={currentHuman.linkedIn} />
      <WrapContainer>
        <Title>
          {currentHuman.careerProfileTextSection.title}
        </Title>
        
        <FirstParagraph>
          {currentHuman.careerProfileTextSection.firstParagraph}
        </FirstParagraph>

        <Subtitle>
          {currentHuman.careerProfileTextSection.subtitle}
        </Subtitle>

        <RetanguleParagraph>
          <GrayRectangle />
          {currentHuman.careerProfileTextSection.retanguleParagraph}
        </RetanguleParagraph>

        <LastParagraph>
          {currentHuman.careerProfileTextSection.lastParagraph}
        </LastParagraph>

        <Subtitle>
          {currentHuman.careerProfileTextSection.subtitle}
        </Subtitle>
      </WrapContainer>
    </Container>
  );
};
  