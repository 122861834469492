import { Container, StyledMasonry, StyledPaper } from "./styles";

import image01 from "../../../assets/images/masonry/image-1.png";
import image02 from "../../../assets/images/masonry/image-2.png";
import image03 from "../../../assets/images/masonry/image-3.png";
import image04 from "../../../assets/images/masonry/image-4.png";
import image05 from "../../../assets/images/masonry/image-5.png";
import image06 from "../../../assets/images/masonry/image-6.png";
import image07 from "../../../assets/images/masonry/image-7.png";
import image08 from "../../../assets/images/masonry/image-8.png";

export const Cards = () => {
  const images = [image01, image02, image03, image04, image05, image06, image07, image08];

  return (
    <Container>
      <StyledMasonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={2}>
        {images.map((item, index) => (
          <StyledPaper key={index} background={item} />
        ))}
      </StyledMasonry>
    </Container>
  );
};
