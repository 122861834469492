import {
  Container,
  ImagePlaceHolder,
  GradientTitle,
  Title,
  Subtitle,
  WrapContainer,
  GradientContainer,
  TextContainer,
  // Button,
  GradientRectangle,
} from "./styles";
import whoweare01 from "../../../assets/images/image-who-we-are.png";
import { useTranslation } from "react-i18next";

export const Banner = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <WrapContainer>
        <TextContainer>
          <GradientTitle variant="h2">
            {t(`about.banner.gradientTitle`)}
          </GradientTitle>
          <Subtitle>{t(`about.banner.subtitle`)}</Subtitle>
          {/* <Button>{t('seeMoreButton')}</Button> */}
        </TextContainer>

        <ImagePlaceHolder src={whoweare01} alt={"banner"}>
          <GradientContainer>
            <Title>{t(`about.banner.title`)}</Title>
            <GradientRectangle />
          </GradientContainer>
        </ImagePlaceHolder>
      </WrapContainer>
    </Container>
  );
};
