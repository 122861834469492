import { Grid, Hidden } from "@mui/material";

import { WrapperSection, WrapperText } from "./styles";
import { CustomersGrid } from "./CustomersGrid";
import { useCustomers } from "../../../shared/hooks/useCustomers";
import { useTranslation } from "react-i18next";

export const Customers = () => {
  const { t } = useTranslation();

  const customers = [
    {
      id: 1,
      name: "Banco BMG",
      image: "bancobmg.png",
    },
    {
      id: 2,
      name: "Colégio bandeirantes",
      image: "colegiobandeirantes.png",
    },
    { id: 3, name: "Ambev", image: "ambev.png" },
    { id: 4, name: "Centauro", image: "centauro.png" },
    { id: 5, name: "Dasa", image: "dasa.png" },

    { id: 6, name: "Dotz", image: "dotz.png" },
    {
      id: 7,
      name: "Creditas",
      image: "creditas.png",
    },
    { id: 8, name: "Dock", image: "dock.png" },
    { id: 9, name: "Credz", image: "credz.png" },
    { id: 10, name: "Contmatic", image: "contmatic.png" },

    { id: 11, name: "São Marcos", image: "laboratoriosaomarcos.png" },
    { id: 12, name: "Rede Celcoin", image: "redecelcoin.png" },
    { id: 13, name: "Funcional", image: "funcional.png" },
    { id: 14, name: "FinaudTec", image: "finaudtec.png" },
    { id: 15, name: "Primavera", image: "primavera.png" },

    { id: 16, name: "Fundação Ezute", image: "fundacaoezute.png" },
    { id: 17, name: "Pernambucanas", image: "pernambucanas.png" },
    { id: 18, name: "Easypay", image: "easypay.png" },
    { id: 19, name: "Grupo Bandeirantes", image: "grupobandeirantes.png" },
    { id: 20, name: "Ifood", image: "ifood.png" },

    { id: 21, name: "Usebens", image: "usebens.png" },
    { id: 22, name: "Voopter", image: "voopter.png" },
    { id: 23, name: "Sibs", image: "sibs.png" },
    { id: 24, name: "Zé Delivery", image: "zedelivery.png" },
    { id: 25, name: "Via Varejo", image: "viavarejo.png" },
  ];

  const { customersDesktopList, customersDesktopListSm } =
    useCustomers(customers);

  return (
    <WrapperSection>
      <WrapperText variant="h2">
        "{t("home.customers.sectionTitle")}"
      </WrapperText>
      <Grid
        container
        rowSpacing={0}
        columnSpacing={0}
        gridAutoColumns={"column"}
        flexWrap={"wrap"}
      >
        <Hidden only={["xl", "md", "sm", "lg"]}>
          {customers?.map((customer) => (
            <CustomersGrid customer={customer} key={customer.id} />
          ))}
        </Hidden>

        <Hidden only={["xs", "sm"]}>
          {customersDesktopList?.map((customer) => (
            <CustomersGrid customer={customer} key={customer.id} />
          ))}
        </Hidden>

        <Hidden only={["xs", "xl", "md", "lg"]}>
          {customersDesktopListSm?.map((customer) => (
            <CustomersGrid customer={customer} key={customer.id} />
          ))}
        </Hidden>
      </Grid>
    </WrapperSection>
  );
};
