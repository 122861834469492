import { StyledCard, StyledCardContent, StyledLink } from './styles'

import { ReactComponent as LinkedInIcon } from '../../assets/linkedin.svg';

export const Card = ({image, name, job, linkedin, link}) => {
  return (
    <StyledCard href={link} background={image}>
      <StyledCardContent>
        <div>
          {name}
          <span>{job != null ? job : ""}</span>
        </div>
        <StyledLink target="_blank" href={linkedin}>
          <LinkedInIcon />
        </StyledLink>
      </StyledCardContent>
    </StyledCard>
  )
}