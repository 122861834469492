import { useTranslation } from "react-i18next";
import { Case } from "../../components/Case";
import { useCases } from "../../shared/hooks/useCases";
import { PageContainer, GradientTitle, Container } from "./styles";
import { isOdd } from "../../shared/utils/mathFunctions";

export const ClientStories = () => {
  const { t } = useTranslation();
  const cases = useCases();

  return (
    <PageContainer>
      <GradientTitle variant="h1">
        {t("clientStories.sectionTitle")}
      </GradientTitle>
      <Container>
        {cases.map((item, index) => {
          return (
            <Case
              key={index}
              reversed={isOdd(index)}
              backgroundImage={item.image}
              title={item.title}
              content={item.content}
              link="/stories/action"
            />
          );
        })}
      </Container>
    </PageContainer>
  );
};
