import { Container, WrapperText, WrapperText2 } from "./styles"

export const RollingText = ({ children, duration }) => {
  return (
    <Container>
      <WrapperText duration={duration}>
        {children}
      </WrapperText>
      <WrapperText2 duration={duration}>
        {children}
      </WrapperText2>
    </Container>
  )
}