import { useTranslation } from 'react-i18next';
import {
  CardWrapper,
  PillarContainer,
  SectionTitle,
  Subtitle,
  SectionFooterText,
} from './styles';

import pillar01 from '../../assets/images/pillar-1.png';
import pillar02 from '../../assets/images/pillar-2.png';
import pillar03 from '../../assets/images/pillar-3.png';

import { Card } from '../CardPillar';

export const Pillar = ({ showFooter = true }) => {
  const { t } = useTranslation();

  return (
    <PillarContainer>
      <SectionTitle>{t('home.pillar.sectionTitle')}</SectionTitle>
      <Subtitle>{t('home.pillar.sectionSubTitle')}</Subtitle>
      <CardWrapper>
        <Card
          image={pillar01}
          title={t('home.pillar.cards.0.title')}
          content={t('home.pillar.cards.0.content')}
        />
        <Card
          image={pillar02}
          title={t('home.pillar.cards.1.title')}
          content={t('home.pillar.cards.1.content')}
        />
        <Card
          image={pillar03}
          title={t('home.pillar.cards.2.title')}
          content={t('home.pillar.cards.2.content')}
        />
      </CardWrapper>
      {showFooter && (
        <footer>
          <SectionFooterText>
            {t('home.pillar.sectionFooterText')}
          </SectionFooterText>
        </footer>
      )}
    </PillarContainer>
  );
};
