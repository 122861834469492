import { AboutQuote, PageContainer, StyledVideoDiv } from './styles';
import { ScrollInteraction } from './ScrollInteraction';
import { Certifications } from './Certifications';
import { Banner } from './Banner';
import { FirstTextSection } from './FirstTextSection';
import { SecondTextSection } from './SecondTextSection';
import { ThirdTextSection } from './ThirdTextSection';
import { Humans } from './Humans';
import { Pillar } from '../../components/Pillar';
import { VideoBanner } from '../../components/VideoBanner';
import { useTranslateGetObject } from '../../shared/hooks/useTranslateGetObject';

export const About = () => {
  const urlVideo = 'https://www.youtube.com/watch?v=Y2D9eXeOwfc';
  const about = useTranslateGetObject('about');

  return (
    <PageContainer>
      <Banner />
      <Pillar showFooter={false} />
      <FirstTextSection />
      <SecondTextSection />
      <StyledVideoDiv>
        <VideoBanner url={urlVideo} />
      </StyledVideoDiv>
      <ScrollInteraction />
      <ThirdTextSection />
      <Certifications />
      <Humans />
      <AboutQuote>{about.quote}</AboutQuote>
    </PageContainer>
  );
};
