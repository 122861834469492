import { CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ContentContainer,
  ContentText,
  Title,
  Button,
} from './styles';

export const Card = ({
  direction,
  variant,
  image,
  lines,
  title,
  content,
  link,
}) => {
  const { t } = useTranslation();
  return (
    <Container direction={direction}>
      {image && <CardMedia component="img" image={image} height={direction === "row" ? "266px" : "280px"} />}
      <ContentContainer>
        <Title>{title}</Title>
        <ContentText lines={lines}>{content}</ContentText>
        <Button variant={variant} to={link}>
          {t('seeMoreButton')}
        </Button>
      </ContentContainer>
    </Container>
  );
};
