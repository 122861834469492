import {
  AboutUsLinks,
  HomeLinks,
  InsightsLinks,
  ServicesLinks,
  SiteInformationsLinks,
} from "../../context/links";
import { isPageVisible } from "../utils/filterPage";

export const useMenuPages = () => {
  const home = HomeLinks.filter(isPageVisible);
  const aboutUs = AboutUsLinks.filter(isPageVisible);
  const services = ServicesLinks.filter(isPageVisible);
  const insights = InsightsLinks.filter(isPageVisible);
  const siteInformations = SiteInformationsLinks.filter(isPageVisible);

  const pages = home
    .concat(aboutUs)
    .concat(services)
    .concat(insights)
    .sort((a, b) => (a.id < b.id ? -1 : 1));

  return { home, aboutUs, services, insights, siteInformations, pages };
};
