export function randomNumberInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function randomNumberInRangeExcludingOne(min, max, toExclude) {
  let randomNumber = randomNumberInRange(min, max);
  if (randomNumber === toExclude && min !== max)
    randomNumber = randomNumberInRangeExcludingOne(min, max, toExclude);
  
  return randomNumber;
};

export function isOdd(index) {
  return (index % 2 === 0);
};

export function convertToBase64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
};