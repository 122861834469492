import { PageContainer, StyledVideoDiv } from "./styles";
import { VideoBanner } from "../../../components/VideoBanner";

export const Grid = () => {
  const urlVideo = "https://www.youtube.com/watch?v=Y2D9eXeOwfc";

  return (
    <PageContainer>
      <StyledVideoDiv>
        <VideoBanner url={urlVideo} height="550px" />
      </StyledVideoDiv>
    </PageContainer>
  );
};
