import { IconButton, InputBase, List, ListItemText } from "@mui/material";
import {
  PageContainer,
  SearchContainer,
  ChipContainer,
  VacancyContainer,
  TagContainer,
  StyledListItemButton,
  Search,
  StyledChip,
  StyledLink,
} from "./styles";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getJobs } from "../../../shared/services/jobs";
import { convertToBase64 } from "../../../shared/utils/mathFunctions";
import { distinct } from "../../../shared/utils/arrayFunctions";

export const Vacancy = () => {
  const [jobs, setJobs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  
  const fetchJobs = async () => {
    try {
      const response = await getJobs();

      const result = JSON.parse(response.data.body).results;
      setJobs([...result]);

      setDepartments([...distinct(result.map((job) => ({ departmentName: job.departmentName, active: true })))]);

    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleClick = (event) => {
    let element = event.target;
    element.classList.toggle('active');

    let deps = [...departments];
    let depToChange = deps.find(d => d.departmentName === element.textContent)
    depToChange.active = !depToChange.active;

    setDepartments(deps);
  }

  const handleTyping = (event) => {
    let element = event.target;
    setNameFilter(element.value);
  }
  
  const { t } = useTranslation();
  const searchPlaceHolder = t("career.search.searchPlaceHolder")

  return (
    <PageContainer>
      <SearchContainer>
        <Search>
          <InputBase
            placeholder={searchPlaceHolder}
            inputProps={{ 'aria-label': searchPlaceHolder }}
            onChange={handleTyping}
          />
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
        </Search>
        <ChipContainer>
          {departments.map((item, index) => (
            <StyledChip className="active" key={index} onClick={handleClick}>{item.departmentName}</StyledChip>
          ))}
        </ChipContainer>
      </SearchContainer>
      <VacancyContainer>
        {departments.filter(department => department.active === true).map((item, index) => (
          <div key={index}>
            <span className="title">{item.departmentName}</span>
            <List className="vacancies" component="nav">
              {jobs.filter(job => job.departmentName === item.departmentName && job.name.toLowerCase().includes(nameFilter.toLowerCase())).map((item2, index2) => (
                <StyledLink key={index2} href={`https://labsit.gupy.io/job/${convertToBase64(`{"source":"gupy_public_page","jobId":${item2.id}}`)}`} target="_blank">
                  <StyledListItemButton divider>
                    <ListItemText className="vacancy-name" primary={item2.name} />
                    <TagContainer>
                      <span className="tag">Remoto</span>
                      <span className="tag">Integral</span>
                    </TagContainer>
                  </StyledListItemButton>
                </StyledLink>
              ))}
            </List>
          </div>
        ))}
      </VacancyContainer>
    </PageContainer>
  );
};
