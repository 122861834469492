import { Link, ListItemButton } from "@mui/material";
import { styled } from "@mui/system";

export const PageContainer = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4rem",
  [theme.breakpoints.down("md")]: {
    gap: "2rem",
  },
}));

export const SearchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
}));

export const Search = styled("div")(({ theme }) => ({
  border: "solid 1px gray",
  padding: "5px",
  display: "flex",
  borderRadius: "100px",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
  input: {
    width: "18rem",
    color: "#FFF",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "2rem",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "15rem",
    },
  },
  button: {
    background: "#FFF",
    borderRadius: "100px",
    color: "#FF0097",
  },
}));

export const ChipContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "wrap",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  fontWeight: "700",
  color: "#FFF",
  gap: "0.5rem",
  '.active': {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
  },
}));

export const StyledChip = styled("div")(({ theme }) => ({
  color: "#FFF",
  border: "1px solid #666666",
  borderRadius: "100px",
  padding: "3px 10px",
  cursor: "pointer",
  fontWeight: "50",
}));

export const VacancyContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "left",
  gap: "40px",
  [theme.breakpoints.down("md")]: {
    gap: "26px",
  },
  div: {
    width: "100%",
    borderColor: "gray",
  },
  ".vacancies": {
    width: "100%",
  },
  ".title": {
    fontSize: "45px",
    fontWeight: "500",
    lineHeight: "150%",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  },
  ".vacancy-name": {
    fontSize: "22px",
    fontWeight: "500",
    lineHeight: "150%",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  ".tag": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 6px",
    color: "#FF0097",
    background: "#202020",
    borderRadius: "2px",
  },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "8px 16px 8px 0px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "6px",
  },
}));

export const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#FFF",
});

export const TagContainer = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "150%",
  gap: "0.5rem",
}));
