import {
  Container,
  WrapContainer,
  Title,
  Subtitle,
  SmallSubtitle,
  TextParagraph,
  SmallTextParagraph,
} from "./styles";

import { useTranslation } from "react-i18next";
import { useTranslateGetObject } from "../../../shared/hooks/useTranslateGetObject";

export const TextSection = () => {
  const { t } = useTranslation();

  const FirstPolicyTextParagraph = useTranslateGetObject("privacyPolicy.firstPolicy.firstOrderedList");
  const FirstPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.firstPolicy.secondOrderedList");
  const SecondPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.secondPolicy.orderedList");
  const ThirdPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.thirdPolicy.orderedList");
  const FourthPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.fourthPolicy.orderedList");
  const FifthPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.fifthPolicy.orderedList");
  const SixthPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.sixthPolicy.orderedList");
  const SeventhPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.seventhPolicy.orderedList");
  const EighthPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.eitgthPolicy.textParagraphs");
  const EighthPolicyParagraphsList = useTranslateGetObject("privacyPolicy.eitgthPolicy.paragrahList");
  const NinthPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.ninthPolicy.textParagraphs");
  const TenthPolicyTextParagraphs = useTranslateGetObject("privacyPolicy.tenthPolicy.textParagraphs");
  const TenthPolicySmallTextParagraphs = useTranslateGetObject("privacyPolicy.tenthPolicy.smallTextParagraphs");
  const EleventhPolicySmallTextParagraphs = useTranslateGetObject("privacyPolicy.eleventhPolicy.textParagraphs");

  return (
    <Container>
      <WrapContainer>
        <Title>{t(`privacyPolicy.title`)}</Title>

        <TextParagraph>{t(`privacyPolicy.introduction`)}</TextParagraph>

        <Subtitle>{t(`privacyPolicy.firstPolicy.subtitle`)}</Subtitle>
        <TextParagraph>{t(`privacyPolicy.firstPolicy.paragraph`)}</TextParagraph>
        <SmallSubtitle>{t(`privacyPolicy.firstPolicy.firstSmallSubtitle`)}</SmallSubtitle>
        <SmallTextParagraph>
          {FirstPolicyTextParagraph.map((text, index) => (
            <li key={`odered-list-${index}`}>{text}</li>
          ))}
        </SmallTextParagraph>

        <SmallSubtitle>{t(`privacyPolicy.firstPolicy.secondSmallSubtitle`)}</SmallSubtitle>
        <SmallTextParagraph>
          {FirstPolicyTextParagraphs.map((text, index) => (
            <li key={`odered-list-${index}`}>{text}</li>
          ))}
        </SmallTextParagraph>


        <Subtitle>{t(`privacyPolicy.secondPolicy.subtitle`)}</Subtitle>
        <TextParagraph>{t(`privacyPolicy.secondPolicy.firstParagraph`)}</TextParagraph>
        <TextParagraph>
          {SecondPolicyTextParagraphs.map((text, index) => (
            <li key={`odered-list-${index}`}>{text}</li>
          ))}
        </TextParagraph>
        <SmallSubtitle>{t(`privacyPolicy.secondPolicy.firstSmallSubtitle`)}</SmallSubtitle>
        <SmallTextParagraph>{t(`privacyPolicy.secondPolicy.firstSmallTextParagraph`)}</SmallTextParagraph>


        <Subtitle>{t(`privacyPolicy.thirdPolicy.subtitle`)}
        </Subtitle>
        <TextParagraph>{t(`privacyPolicy.thirdPolicy.firstParagraph`)}<br/></TextParagraph>
        <TextParagraph>
          {ThirdPolicyTextParagraphs.map((text, index) => (
            <li key={`odered-list-${index}`}>{text}</li>
          ))}
        </TextParagraph>


        <Subtitle>{t(`privacyPolicy.fourthPolicy.subtitle`)}</Subtitle>
        <TextParagraph>{t(`privacyPolicy.fourthPolicy.firstParagraph`)}<br/></TextParagraph>
        <TextParagraph>
          {FourthPolicyTextParagraphs.map((text, index) => (
            <li key={`odered-list-${index}`}>{text}</li>
          ))}
        </TextParagraph>
        <TextParagraph>{t(`privacyPolicy.fourthPolicy.secondParagraph`)}</TextParagraph>


        <Subtitle>{t(`privacyPolicy.fifthPolicy.subtitle`)}</Subtitle>
        <TextParagraph>{t(`privacyPolicy.fourthPolicy.firstParagraph`)}<br/></TextParagraph>
        <TextParagraph>
          {FifthPolicyTextParagraphs.map((text, index) => (
            <p key={`odered-list-${index}`}>{text}</p>
          ))}
        </TextParagraph>
        <TextParagraph>{t(`privacyPolicy.fourthPolicy.secondParagraph`)}</TextParagraph>
        
        
        <Subtitle>{t(`privacyPolicy.sixthPolicy.subtitle`)}</Subtitle>
        <TextParagraph>{t(`privacyPolicy.sixthPolicy.firstParagraph`)}</TextParagraph>
        <TextParagraph>
          {SixthPolicyTextParagraphs.map((text, index) => (
            <li key={`odered-list-${index}`}>{text}</li>
          ))}
        </TextParagraph>
        <TextParagraph>{t(`privacyPolicy.sixthPolicy.secondParagraph`)}</TextParagraph>


        <Subtitle>{t(`privacyPolicy.seventhPolicy.subtitle`)}</Subtitle>
        <TextParagraph>{t(`privacyPolicy.seventhPolicy.firstParagraph`)}</TextParagraph>
        <TextParagraph>{t(`privacyPolicy.seventhPolicy.secondParagraph`)}</TextParagraph>
        <TextParagraph>
          {SeventhPolicyTextParagraphs.map((text, index) => (
            <li key={`odered-list-${index}`}>{text}</li>
          ))}
        </TextParagraph>

        <SmallSubtitle>{t(`privacyPolicy.seventhPolicy.firstSmallSubtitle`)}</SmallSubtitle>
        <SmallTextParagraph>{t(`privacyPolicy.seventhPolicy.firstSmallTextParagraph`)}</SmallTextParagraph>


        <Subtitle>{t(`privacyPolicy.eitgthPolicy.subtitle`)}</Subtitle>
        <TextParagraph>
          {EighthPolicyTextParagraphs.map((text, index) => (
            <p key={`odered-list-${index}`}>{text}</p>
          ))}
        </TextParagraph>
        <TextParagraph>
          {EighthPolicyParagraphsList.map((text, index) => (
            <p key={`odered-list-${index}`}>{text}</p>
          ))}
        </TextParagraph>


        <Subtitle>{t(`privacyPolicy.ninthPolicy.subtitle`)}</Subtitle>
        <TextParagraph>
          {NinthPolicyTextParagraphs.map((text, index) => (
            <p key={`odered-list-${index}`}>{text}</p>
          ))}
        </TextParagraph>


        <Subtitle>{t(`privacyPolicy.tenthPolicy.subtitle`)}</Subtitle>
        <TextParagraph>
          {TenthPolicyTextParagraphs.map((text, index) => (
            <p key={`odered-list-${index}`}>{text}</p>
          ))}
        </TextParagraph>
        <SmallSubtitle>{t(`privacyPolicy.tenthPolicy.firstSmallSubtitle`)}</SmallSubtitle>
        <SmallTextParagraph>
          {TenthPolicySmallTextParagraphs.map((text, index) => (
            <p key={`odered-list-${index}`}>{text}</p>
          ))}
        </SmallTextParagraph>


        <Subtitle>{t(`privacyPolicy.eleventhPolicy.subtitle`)}</Subtitle>
        <TextParagraph>
          {EleventhPolicySmallTextParagraphs.map((text, index) => (
            <p key={`odered-list-${index}`}>{text}</p>
          ))}
        </TextParagraph>

      </WrapContainer>
    </Container>
  );
};
