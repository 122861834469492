import { useState } from 'react';
import {
  Container,
  Dot,
  DotContainer,
  ImagePlaceHolder,
  NavButton,
  Title,
  Subtitle,
  WrapContainer,
  StyledImageListItem,
  Button,
  CarouselController,
} from './styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import banner01 from '../../../assets/images/carousel/banner.png';
import banner02 from '../../../assets/images/carousel/banner-loja.png';
import { useTranslation } from 'react-i18next';

const images = [
  {
    image: banner01,
    linkTo: "/about",
    openNewWindow: false
  },
  {
    image: banner02,
    linkTo: "https://loja.labsit.io/labsit",
    openNewWindow: true
  }
];

export const BannerCarousel = () => {
  const { t } = useTranslation();
  const [currImg, setCurrImg] = useState(0);

  const HandleButtonClick = (e) => {
    e.preventDefault();
    if (images[currImg].openNewWindow)
      window.open(images[currImg].linkTo);
    else
      window.location.href = images[currImg].linkTo;
  }

  return (
    <Container>
      <StyledImageListItem>
        <ImagePlaceHolder src={images[currImg].image} alt={'banner'} />
      </StyledImageListItem>

      <WrapContainer>
        <Title>{t(`home.carousel.banners.${currImg}.title`)}</Title>
        <Subtitle>{t(`home.carousel.banners.${currImg}.subtitle`)}</Subtitle>

        <Button onClick={HandleButtonClick}>{t("seeMoreButton")}</Button>
 
        <CarouselController>
          <NavButton
            left
            onClick={() => {
              currImg > 0
                ? setCurrImg(currImg - 1)
                : setCurrImg(images.length - 1);
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: 30 }} />
          </NavButton>

          <DotContainer>
            {images.map((dot, index) => (
              <Dot
                key={`${dot.image}-${index}`}
                active={index === currImg}
                onClick={() => setCurrImg(index)}
              />
            ))}
          </DotContainer>

          <NavButton
            right
            onClick={() => {
              currImg < images.length - 1
                ? setCurrImg(currImg + 1)
                : setCurrImg(0);
            }}
          >
            <ArrowForwardIosIcon style={{ fontSize: 30 }} />
          </NavButton>
        </CarouselController>
      </WrapContainer>
    </Container>
  );
};
