import {
  AppBar,
  Box,
  Drawer,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";

export const HeaderContainer = styled(AppBar)({
  background: "#0F0F0F",
  position: "relative",
});

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  margin: "0 auto",
  height: "80px",
  width: "100%",
  display: "flex",
  gap: "2.5rem",
  [theme.breakpoints.up("lg")]: {
    padding: "0 5rem",
    maxWidth: "120rem",
  },
}));

export const DesktopBox = styled(Box)(({ theme }) => ({
  height: "80px",
  width: "100%",
  display: "flex",
  gap: "2rem",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const Navbar = styled("nav")({
  display: "flex",
  justifyContent: "space-evenly",
  gap: "1rem",
  whiteSpace: "nowrap",
  a: {
    fontSize: "1rem",
    lineHeight: "1.5",
    fontWeight: "bold",
    color: "#FFF",
    textDecoration: "none",
    transition: "color 300ms",
  },
  "a:hover, & a.active": {
    color: "#FF0097",
  },
});

export const SearchAndContact = styled("div")({
  justifySelf: "flex-end",
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
});

export const Search = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.25rem",
  input: {
    width: "7.5rem",
    background: "transparent",
    border: "0",
    color: "#FFF",
    textAlign: "right",
    fontWeight: "bold",
    lineHeight: "2rem",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: "#FFF",
      textAlign: "right",
      fontWeight: "bold",
    },
  },
}));

export const ContactButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0px 1rem",
  gap: "0.5rem",
  height: "48px",
  background: "#FF0097",
  borderRadius: "100px",
  flex: "none",
  flexGrow: "0",
  color: "#FFF",
  fontWeight: "bold",
  textDecoration: "none",
  minWidth: "9rem",
  "&:hover": {
    background: "#FF0097",
  },
  span: {
    color: "#fff",
    fontSize: "1rem",
    lineHeight: "1.5",
  },
}));

export const LanguageButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  border: "2px solid #3a3a3a",
  borderRadius: "100px",
  gap: "0.5rem",
  height: "48px",
  minWidth: "9rem",
  padding: "0 1rem",
  span: {
    color: "#fff",
    fontSize: "1rem",
    lineHeight: "1.5",
  },
}));

export const LanguageMenu = styled(Menu)({
  top: "65px",
  ul: {
    display: "flex",
    flexDirection: "column",
    width: "20rem",
    padding: "1.5rem",
    gap: "0.625rem",
    background: "#1a1a1a",
    color: "white",
    border: "1px solid #3a3a3a",
    borderRadius: "8px",
  },
});

export const LanguageDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: 1301,
  ".MuiDrawer-paperAnchorBottom": {
    display: "flex",
    gap: "1.5rem",
    borderRadius: "1.5rem 1.5rem 0 0",
    background: "#1a1a1a",
    padding: "4rem 1.5rem 2.5rem 1.5rem",
  },
  h1: {
    color: "white",
    fontSize: "1.5rem",
    margin: "0",
    padding: "0",
  },
}));

export const LanguageMenuItem = styled(MenuItem)({
  display: "flex",
  justifyContent: "space-between",
  color: "#fff",
  padding: "0",
  svg: {
    display: "none",
  },
  "&:hover": {
    color: "#FF0097",
  },
  "&.active": {
    color: "#FF0097",
    svg: {
      display: "block",
    },
  },
});

export const MobileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  div: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({}));

export const MobileMenu = styled(Menu)(({ theme }) => ({
  background: "#0f0f0f",
  "& > div": {
    background: "#0f0f0f",
    width: "100%",
    height: "100%",
    top: "0px !important",
    left: "0px !important",
    flex: "1",
    maxWidth: "100%",
    borderRadius: "0px",
  },
  "& ul": {
    padding: "2.5rem 1.5rem",
    background: "#0f0f0f",
    color: "#FFF",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    alignContent: "stretch",
    gap: "2rem",
    a: {
      color: "#FFF",
      fontWeight: "bold",
      textDecoration: "none",
    },
    footer: {
      marginTop: "auto",
      marginBottom: "0px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "1.75rem",
    },
  },
}));

export const MobileMenuTitle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  h1: {
    margin: "0px",
    padding: "0px",
    fontSize: "2rem",
    lineHeight: "1.5",
  },
}));
export const CloseButton = styled(IconButton)({});

export const MobileMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "#FFF",
  padding: "0",
  a: {
    textDecoration: "none",
  },
}));
