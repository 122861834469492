import {Container, Header, Avatar, Title, Content, 
  Description, List, ListItem} from './styles'

export const Profile = ({ profile }) => {
  return (
    <Container>
      <Header>
        <Avatar img={profile.avatar} />
        <Title>{profile.title}</Title>
      </Header>
      <Content>
        <Description>{profile.description}</Description>
        <List>
          {profile.topics.map((topic, index) => <ListItem key={index}>{topic}</ListItem>)}
        </List>
      </Content>
    </Container>)
}