import { styled } from "@mui/system";

import {Paragraph} from "../../../components/Paragraph";

export const Container = styled("section")({
  width: "100%",
});

export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  padding: "0 1.5rem",

  [theme.breakpoints.down("sm")]: {
    padding: "0",
    fontSize: "2rem",
  },
}));

export const FirstParagraph = styled(Paragraph)(({ theme }) => ({}));

export const SecondParagraph = styled(Paragraph)(({ theme }) => ({}));

export const LastParagraph = styled(Paragraph)(({ theme }) => ({}));
