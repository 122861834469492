import { styled } from "@mui/system";

export const PageContainer = styled("div")(({ theme }) => ({
  background: "#0f0f0f",
  margin: "0 auto",
  
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1.5rem"
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "120rem"
  }
}));