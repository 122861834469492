import {
  Container,
  WrapContainer,
  GradientTitle,
  Subtitle,
  FirstParagraph,
  SecondParagraph,
  ThirdParagraph,
} from "./styles";

import { useTranslation } from "react-i18next";

export const FirstTextSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <WrapContainer>
        <GradientTitle>
          {t(`about.firstTextSection.gradientTitle`)}
        </GradientTitle>
        <Subtitle>{t(`about.firstTextSection.subtitle`)}</Subtitle>
        <FirstParagraph>
          {t(`about.firstTextSection.firstParagraph`)}
        </FirstParagraph>
        <SecondParagraph>
          {t(`about.firstTextSection.secondParagraph`)}
        </SecondParagraph>
        <ThirdParagraph>
          {t(`about.firstTextSection.thirdParagraph`)}
        </ThirdParagraph>
      </WrapContainer>
    </Container>
  );
};
