import { FullContainer } from "../../../components/FullContainer";

export const Sale = () => {
  const email = "contato@labsit.io";
  const title = "home.sale.title";
  const image = "/images/nathan-dumlao.png";
  const labelButton = "home.sale.labelButton";
  const link = `mailto:${email}`;

  return (
    <FullContainer
      title={title}
      image={image}
      labelButton={labelButton}
      rowReverse={false}
      link={link}
    />
  );
};
