import { styled } from "@mui/system";

export const Container = styled("section")(({ theme }) => ({
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "1rem",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "120rem",
  },
  [theme.breakpoints.down("sm")]:{
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    overflowX: "scroll",
  }
}))