import {Routes, Route, useLocation } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { DefaultLayout } from './layout/DefaultLayout';
import { ClientStories } from './pages/ClientStories';
import { OffersPage } from './pages/Offers';
import { StoriesAction } from './pages/ClientStories/Action';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Career } from './pages/Career';
import { CareerProfile } from './components/CareerProfile';
import {Agiletrends} from "./pages/Agiletrends";

const RedirectHandler = () => {
  const location = useLocation();
  window.location.replace(`https://labsit.ch${location.pathname}${location.search}`);
  return null;
};

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/agiletrends" element={<Agiletrends />} />
        <Route path="/career" element={<Career />} />
        <Route path="/stories" element={<ClientStories />} />
        <Route path="/stories/action" element={<StoriesAction />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/offers/:slug" element={<OffersPage />} />
        <Route path='/career/:slug' element={<CareerProfile />} />
        <Route path="/atlassian/*" element={<RedirectHandler />} />
      </Route>
    </Routes>
  );
};
