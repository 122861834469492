import { IconsSocialNetworks } from "./styles"
import { ReactComponent as FacebookIcon } from '../../assets/facebook-circled.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram-circled.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedin-circled.svg';
import { ReactComponent as YouTubeIcon } from '../../assets/youtube-circled.svg';
import { ReactComponent as SpotifyIcon } from '../../assets/spotify-circled.svg';
import { ReactComponent as MediumIcon } from '../../assets/medium-circled.svg';
import { Link } from "@mui/material";

const facebookLink = "https://www.facebook.com/labsit.io";
const instagramLink = "https://www.instagram.com/labsit.io/";
const linkedInLink = "https://www.linkedin.com/company/labsit.io";
const youTubeLink = "https://www.youtube.com/channel/UCGhpVAnRbWHYyUEoHEb-lNA";
const spotifyLink = "https://open.spotify.com/show/7GyzBps5GcmnrDT7JPTdwJ";
const mediumLink = "https://medium.com/labsit";

export const SocialNetworks = () => {
    return (
        <IconsSocialNetworks>
            <Link target="_blank" href={facebookLink}>
                <FacebookIcon />
            </Link>
            <Link target="_blank" href={instagramLink}>
                <InstagramIcon />
            </Link>
            <Link target="_blank" href={linkedInLink}>
                <LinkedInIcon />
            </Link>
            <Link target="_blank" href={youTubeLink}>
                <YouTubeIcon />
            </Link>
            <Link target="_blank" href={spotifyLink}>
                <SpotifyIcon />
            </Link>
            <Link target="_blank" href={mediumLink}>
                <MediumIcon />
            </Link>
        </IconsSocialNetworks>
    );
}
