import {useEffect} from "react";

export const Agiletrends = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "/agiletrends.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

  return (
      <div>
      </div>
  );
};
