import { styled } from "@mui/system";
import { Typography } from "@mui/material";

export const SectionContainer = styled("section")(({ theme }) => ({
  padding: "5rem 15rem",
  background: "#1A1A1A",
  gap: "40px",
  display: "grid",
  [theme.breakpoints.down("xl")]: {
    padding: "5rem 10rem",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "5rem 5rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: "5rem 5rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5rem 1.5rem",
    gap: "24px",
  }, 
}));

export const SectionTitle = styled(Typography)({
  fontWeight: "700",
  fontSize: "2rem",
  lineHeight: "1.5",
});

export const OffersContainer = styled("div")(({ theme }) => ({
  display: "grid",
  width: "100%",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "24px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const Cell = styled("div")(({ span=1, spanDirection="vertical"  }) => {
  return (spanDirection === "vertical") ? { gridRow: `span ${span}` } : { gridColumn: `span ${span}` }
});