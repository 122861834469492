import { Typography } from "@mui/material"
import { styled } from "@mui/system"

export const Text = styled(Typography)((props) => ({
  padding: "0",
  margin: "0",
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1.5rem",
  marginBottom: "2rem",
}));