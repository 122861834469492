import { v4 as uuidv4 } from "uuid";

const nextMultipleOf5 = (num) => {
  return Math.ceil(num / 5) * 5;
};

const isMultipleOf5 = (num) => {
  return num % 5 === 0;
};

const buildLabsitCustomers = () => {
  return { id: uuidv4(), name: "Labsit", image: "labsit.png" };
};

const useCustomers = (customers) => {
  const totalCustomers = customers.length;
  let desktopListClone = Object.assign([], customers);
  let desktopSmListClone = Object.assign([], customers);

  const nextNumberMultipleOf5 = nextMultipleOf5(totalCustomers);
  const isEven = Math.abs(totalCustomers % 2) === 0;
  const isMultple = isMultipleOf5(totalCustomers);

  const missingForMultiplesFive = nextNumberMultipleOf5 - totalCustomers;

  //Complete the list with labs objects when we have 5 columns
  Array.from(Array(missingForMultiplesFive), () =>
    desktopListClone.push(buildLabsitCustomers())
  );
  const customersDesktopList = isMultple ? customers : desktopListClone;

  //Complete with the labs object when we have 2 columns and the total of items is even
  desktopSmListClone.push(buildLabsitCustomers());
  const customersDesktopListSm = isEven ? customers : desktopSmListClone;

  return { customersDesktopList, customersDesktopListSm };
};

export { useCustomers };
