import { styled } from "@mui/system";
import { ImageListItem } from "@mui/material";
import { GradientText } from "../../../components/GradientText";

export const Container = styled("section")(({ src, theme }) => ({
  display: "flex",
  margin: "40px auto auto auto",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  
  [theme.breakpoints.down("sm")]: {
    margin: "0 auto",
    marginLeft: "0",
  },
}));

export const StyledImageListItem = styled(ImageListItem)({
  margin: "0 auto",
  marginLeft: "0",
  position: "relative",
  top: "2.5rem",
});

export const ImagePlaceHolder = styled("div")(({ src, theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  background: `url(${src})`,
  width: "35rem",
  backgroundSize: "cover",
  backgroundColor: "black",
  aspectRatio: "2.5 / 3",

  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    width: "20rem",
    backgroundSize: "cover",
  },
}));

export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  justifyContent: "space-between",
  gap: "4rem",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    fontSize: "2rem",
    gap: "0",
},
}));

export const TextContainer = styled("div")(({ theme }) => ({

  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1.5rem",
  },
}));

export const GradientTitle = styled(GradientText)(({ theme }) => ({
  fontSize: "3.75rem",
  padding: "0",
  margin: "0",
  width: "100%",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

export const Subtitle = styled("h3")(({ theme }) => ({
  fontSize: "1.375rem",
  display: "flex", 
  alignItems: "center",
  padding: "0",
  margin: "0",
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",
  marginLeft: "1.70rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.375rem",
    marginLeft: "1rem",
  },
}));

export const StyledLinkedInContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));