import { PageContainer } from "./styles";
import { Banner } from "./Banner";
import { TextSection } from "./TextSection";
import { Grid } from "./Grid";
import { Vacancy } from "./Vacancy";

export const Career = () => {
  return (
    <PageContainer>
      <Banner />
      <TextSection />
      <Grid />
      <Vacancy />
    </PageContainer>
  )
}