import { styled } from '@mui/system';

export const PageContainer = styled('main')(({ theme }) => ({
  background: '#0f0f0f',
  margin: '0 auto',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '120rem',
  },
}));

export const PillarSection = styled('section')(({ theme }) => ({
  padding: '160px 80px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '80px 24px 0',
  },
}));
