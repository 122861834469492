import { Container,  ImagePlaceHolder, GradientTitle, Subtitle, WrapContainer, TextContainer, StyledLinkedInContainer } from "./styles"
import { ReactComponent as LinkedInIcon } from '../../../assets/linkedIn-icon.svg';
import { Link } from "@mui/material";

export const Banner = ({ image, name, job, linkedIn }) => {

  return (
    <Container>
      <WrapContainer>
        <ImagePlaceHolder src={image} alt={"banner"}></ImagePlaceHolder>
        <TextContainer>
          <GradientTitle>{name}</GradientTitle>
          <StyledLinkedInContainer>
            <Link target="_blank" href={linkedIn}>
              <LinkedInIcon />
            </Link>
            <Subtitle>{job}</Subtitle>
          </StyledLinkedInContainer>
        </TextContainer>
      </WrapContainer> 
    </Container>
  )
}