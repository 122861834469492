import { styled } from "@mui/system";

export const PageContainer = styled("main")(({ theme }) => ({
  margin: "0 auto",
  padding: "0 5rem",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1.5rem"
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "120rem"
  }
}));