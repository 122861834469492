import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  WrapperFlexContainer,
  WrapperTypography,
  WrapperDivButton,
  WrapperFlexItens,
  WrapperButton,
  WrapperImage,
  WrapperItem,
  WrapperSection,
} from "./styles";
import ReactGA from "react-ga4";

export const FullContainer = ({
  title,
  image,
  labelButton,
  rowReverse,
  link,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <WrapperSection rowReverse={rowReverse}>
        <WrapperItem>
          <WrapperFlexContainer>
            <WrapperImage>
              <img src={image} srcSet={image} alt={"imagem"} loading="lazy" />
            </WrapperImage>
          </WrapperFlexContainer>
        </WrapperItem>
        <WrapperItem>
          <WrapperFlexContainer>
            <WrapperFlexItens>
              <WrapperTypography>
                <Typography
                  variant="h4"
                  component="h4"
                  color={"#FFFFFF"}
                  mb={3}
                >
                  {t(title)}
                </Typography>
              </WrapperTypography>

              <WrapperDivButton>
                <WrapperButton href={`${link}`} target="_blank" onClick={() => {
                  ReactGA.event({
                    category: 'User',
                    action: link.indexOf('mailto') >= 0 ? 'conversion' : 'banner'
                  })
                }}>
                  {t(labelButton)}
                </WrapperButton>
              </WrapperDivButton>
            </WrapperFlexItens>
          </WrapperFlexContainer>
        </WrapperItem>
      </WrapperSection>
    </>
  );
};
