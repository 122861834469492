import { styled } from "@mui/system";
import { ImageListItem, ButtonBase } from "@mui/material";

export const Container = styled("section")(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  height: "41.68rem",
}));

export const StyledImageListItem = styled(ImageListItem)({
  margin: "0 auto",
  position: "relative",
});

export const ImagePlaceHolder = styled("div")(({ src }) => ({
  backgroundImage: `url(${src})`,
  witdh: "100%",
  height: "41.68rem",
  backgroundPosition: "center",
  margin: "0 auto",
}));

export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bottom: "340px",
  position: "relative",
  padding: "0 5rem",

  [theme.breakpoints.down("sm")]: {
    padding: "0 1.5rem",
    fontSize: "2rem",
    bottom: "430px",
    paddingRight: "0",
  },
}));

export const Title = styled("h1")(({ theme }) => ({
  fontSize: "2.8125rem",
  padding: "0",
  margin: "0",
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

export const Subtitle = styled("h3")(({ theme }) => ({
  fontSize: "1.375rem",
  padding: "0",
  margin: "0",
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem",
  },
}));

export const Button = styled(ButtonBase)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "2rem",
  height: "3rem",
  width: "6.18rem",
  background: "#FF0097",
  borderRadius: "100px",
  color: "#FFF",
  fontWeight: "bold",
  textDecoration: "none",
  fontSize: "1rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const CarouselController = styled("div")({
  display: "flex",
  marginBottom: "5rem",
  gap: "0.5rem",
  coursor: "pointer",
});

export const DotContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: "1rem",
});

export const Dot = styled("div")((props) => ({
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  cursor: "pointer",
  border: "1px solid #ffffff",
  backgroundColor: `${props.active === true ? `#FF0097` : `none`}`,
}));

export const NavButton = styled("div")({
  display: "flex",
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
  color: "#FF0097",
  alignItems: "center",
  svg: { height: "17.41px" },
});

export const WrapperNavigation = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: "1rem",
});
