import { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ContainerHuman,
  AnotherHuman,
  StyledArrowForwardOutlined,
  StyledLink,
  TextContainer,
  ContentContainer,
} from './styles';
import { Card } from '../../../components/CardHuman';
import {
  randomNumberInRange,
  randomNumberInRangeExcludingOne,
} from '../../../shared/utils/mathFunctions';
import { useTranslateGetObject } from '../../../shared/hooks/useTranslateGetObject';

export const Humans = () => {
  const { t } = useTranslation();

  const humans = useTranslateGetObject("humans");
  const [index, setIndex] = useState(randomNumberInRange(0, humans.length - 1));
  const currentHuman = humans[index];

  const handleClick = () =>
    setIndex(randomNumberInRangeExcludingOne(0, humans.length - 1, index));

  return (
    <Container>
      <ContainerHuman>
        <SwitchTransition>
          <CSSTransition key={index} timeout={800} classNames="fade">
            <Card
              image={currentHuman.image}
              name={currentHuman.name}
              linkedin={currentHuman.linkedIn}
              job={currentHuman.job}
              link={currentHuman.link}
            />
          </CSSTransition>
        </SwitchTransition>
        <ContentContainer>
          <SwitchTransition>
            <CSSTransition key={index} timeout={500} classNames="fade">
              <TextContainer>
                <span className="name">{currentHuman.name}</span>
                <span className="job">{currentHuman.job}</span>
                <span className="description">{currentHuman.description}</span>
              </TextContainer>
            </CSSTransition>
          </SwitchTransition>
          <StyledLink onClick={handleClick}>
            <AnotherHuman>
              {t(`about.humans.seeAnotherButton`)}{' '}
              <StyledArrowForwardOutlined />
            </AnotherHuman>
          </StyledLink>
        </ContentContainer>
      </ContainerHuman>
    </Container>
  );
};
