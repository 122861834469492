import { styled } from "@mui/system";
import { ImageListItem } from "@mui/material";

export const Container = styled("section")(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  marginBottom: "-350px",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "-470px",
  },
}));

export const StyledImageListItem = styled(ImageListItem)({
  margin: "0 auto",
  position: "relative",
});

export const ImagePlaceHolder = styled("div")(({ src, theme }) => ({
  backgroundImage: `url(${src})`,
  witdh: "100%",
  height: "41.68rem",
  backgroundPosition: "center",
  backgroundSize:"cover",
  margin: "0 auto",

  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    width: "100%",
    height: "21rem",
    backgroundSize: "cover",
  },
}));




export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  bottom: "340px",
  position: "relative",
  padding: "0 5rem",

  [theme.breakpoints.down("sm")]: {
    padding: "0 1.5rem",
    fontSize: "2rem",
    bottom: "4rem",
    paddingRight: "0",
  },
}));

export const Title = styled("h1")(({ theme }) => ({
  fontSize: "2.8125rem",
  padding: "0",
  margin: "0",
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

export const GradientContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "15rem",
  width: "36rem",


  [theme.breakpoints.down("sm")]: {
    left: "0",
    width: "21rem",
    alignItems: "center",
    marginBottom: "25rem",
  },
}));

export const GradientRectangle = styled("div")(() => ({
  background: "linear-gradient(90deg, #FF0097 0%, #0069E6 100%)",
  width: "128px",
  height: "3px",
  marginTop: "auto",
  marginBottom: "0",
}));
