import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

export const PageContainer = styled('main')({});

export const TopicsContainer = styled('div')({
  marginTop: '2.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '2.5rem',
});

export const Banner = styled('header')(({ img, theme }) => ({
  marginBottom: '5rem',
  backgroundImage: `url(${img})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  width: '100%',
  height: '440px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  padding: '0 1.5rem',
}));

export const BannerTitle = styled(Typography)(({ theme }) => ({
  fontSize: '3.75rem',
  fontWeight: '400',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    textAlign: 'center',
  },
}));

export const DescriptionSection = styled('section')(({ theme }) => ({
  margin: '0 auto',
  width: '100%',
  maxWidth: '63.75rem',
  padding: '0 2.5rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0 1.5rem',
  },
}));

export const DescriptionTitle = styled(Typography)({
  marginBottom: '1.5rem',
  fontSize: '2rem',
  lineHeight: '1.5',
  fontWeight: '700',
});

export const DescriptionText = styled(Typography)({
  fontSize: '1rem',
});

export const ProfileSection = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  margin: '2.5rem auto auto',
  width: '63.75rem',
  padding: '0 2.5rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '0 1.5rem',
  },
}));

export const ProfileTitle = styled(Typography)({
  marginBottom: '1.5rem',
  fontSize: '3.75rem',
  lineHeight: '1.5',
  fontWeight: '400',
});

export const CarouselSection = styled('section')(({ theme }) => ({
  margin: '0 auto',
  width: '63.75rem',
  padding: '100px 0 120px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: '1.5rem',
  },
}));

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  height: '480px',
  [theme.breakpoints.down('sm')]: {
    height: '144px',
  },
  '& > div > button': {
    backgroundColor: '#0f0f0f',
    border: '1px solid #3a3a3a',
  },
}));

export const ImagePlacholder = styled('div')(({ image, theme }) => ({
  background: `url(${image}) no-repeat`,
  backgroundSize: 'cover',
  margin: '0 auto',
  backgroundPosition: 'center',
  width: 'calc(100% - 124px)',
  height: '480px',
  [theme.breakpoints.down('sm')]: {
    width: '280px',
    height: '144px',
  },
}));

export const OtherOfferSection = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#1a1a1a',
  padding: '5rem 10rem',
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100rem',
    gap: '24px',
    '& > h1': {
      fontSize: '2rem',
      fontWeight: '700',
      margin: '0',
    },
  },
  [theme.breakpoints.down('lg')]: {
    padding: '5rem 2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '5rem 1.5rem',
    '& > div > div': {
      flexDirection: 'column',
    },
  },
}));

export const GridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: '1fr',
  gap: '1rem',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(4, 1fr)',
  },
}));

export const ReportSection = styled('section')(({ theme }) => ({
  padding: '5rem 1.5rem',
  [theme.breakpoints.down('sm')]: {
    padding: '5rem 1.5rem 0',
  },
}));

export const ReportContainer = styled('div')({
  maxWidth: '1020px',
  margin: '0 auto',
});

export const ContentContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Image = styled('div')(({ theme }) => ({
  display: 'flex',
  background: "url('/images/business-agility-report.png') no-repeat",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '378px',
  alignItems: 'flex-end',
  justifyContent: 'center',
  padding: '0 5rem 1.5rem',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.75rem 1rem',
    gap: '0.5rem',
    height: '48px',
    border: '0',
    background: '#FF0097',
    borderRadius: '100px',
    flex: '1',
    color: '#FFF',
    fontWeight: '700',
    textDecoration: 'none',
    minWidth: '9rem',
    '&:hover': {
      background: '#FF0097',
    },
    span: {
      color: '#fff',
      fontSize: '1rem',
      lineHeight: '1.5',
    },
  },
}));

export const ContentText = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  h1: {
    margin: '0',
    fontSize: '1.375rem',
    fontWeight: '700',
  },
  p: {
    margin: '0',
    fontSize: '1rem',
    lineHeight: '1.5',
    fontWeight: '500',
  },
});
