import { Topic } from "../../components/Topic";
import { Card } from "../../components/Card";

import {
  PageContainer,
  Banner,
  BannerTitle,
  DescriptionSection,
  DescriptionTitle,
  DescriptionText,
  TopicsContainer,
  ProfileSection,
  ProfileTitle,
  CarouselSection,
  StyledCarousel,
  ImagePlacholder,
  OtherOfferSection,
  GridContainer,
  ReportSection,
  ReportContainer,
  ContentContainer,
  ContentText,
  Image,
} from "./styles";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/arrow-left.svg";

import { useTranslateGetObject } from "../../shared/hooks/useTranslateGetObject";
import { Profile } from "../../components/Profile";
import { useParams } from "react-router-dom";

export const OffersPage = () => {
  const { slug } = useParams();
  const pageOffer = useTranslateGetObject("pageOffer");
  const offers = useTranslateGetObject("offers");
  const currentOffer = offers?.find((offer) => offer.slug === slug);
  const otherOffers = offers
    ?.filter((offer) => offer.slug !== slug)
    .sort(() => Math.random() - 0.5)
    .slice(0, 4);

  return (
    <PageContainer>
      {currentOffer.banner && (
        <Banner img={currentOffer.banner.image}>
          <BannerTitle>{currentOffer.banner.text}</BannerTitle>
        </Banner>
      )}
      <DescriptionSection>
        <DescriptionTitle>{currentOffer.title}</DescriptionTitle>
        <DescriptionText>{currentOffer.description}</DescriptionText>
        <TopicsContainer>
          {currentOffer.topics &&
            currentOffer.topics.map((topic, index) => (
              <Topic key={index} title={topic.title} content={topic.content} />
            ))}
        </TopicsContainer>
      </DescriptionSection>
      {currentOffer.profiles && (
        <ProfileSection>
          <ProfileTitle>{pageOffer.profilesTitle}</ProfileTitle>
          {currentOffer.profiles.map((profile, index) => (
            <Profile key={index} profile={profile} />
          ))}
        </ProfileSection>
      )}
      {currentOffer.images && (
        <CarouselSection>
          <StyledCarousel
            indicators={false}
            stopAutoPlayOnHover={true}
            autoPlay={false}
            swipe={true}
            navButtonsAlwaysVisible={true}
            animation="slide"
            duration="500"
            PrevIcon={<ArrowLeft />}
            NextIcon={<ArrowRight />}
          >
            {currentOffer.images.map((image, index) => (
              <ImagePlacholder key={index} image={image} />
            ))}
          </StyledCarousel>
        </CarouselSection>
      )}
      <OtherOfferSection>
        <div>
          <h1>{pageOffer.otherOffersTitle}</h1>
          <GridContainer>
            {otherOffers.map((offer, index) => (
              <Card
                key={index}
                variant="outlined"
                lines={3}
                title={offer.title}
                content={offer.description}
                link={offer.link}
              />
            ))}
          </GridContainer>
        </div>
      </OtherOfferSection>
      {currentOffer.report && (
        <ReportSection>
          <ReportContainer>
            <h1>{pageOffer.reportTitle}</h1>
            <ContentContainer>
              <Image src={currentOffer.report.image}>
                {/* {currentOffer.report.button && (
                  <button>{currentOffer.report.button.label}</button>
                )} */}
              </Image>
              <ContentText>
                <h1>{currentOffer.report.title}</h1>
                <p>{currentOffer.report.description}</p>
              </ContentText>
            </ContentContainer>
          </ReportContainer>
        </ReportSection>
      )}
    </PageContainer>
  );
};
