import {
  Container,
  WrapContainer,
  Title,
  Subtitle,
  FirstParagraph,
  LastParagraph,
  GrayRectangle,
} from "./styles";

import { useTranslation } from "react-i18next";

export const TextSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <WrapContainer>
        <Title>{t(`clientStories.clientStoriesTextSection.title`)}</Title>

        <FirstParagraph>
          {t(`clientStories.clientStoriesTextSection.firstparagraph`)}
        </FirstParagraph>

        <Subtitle>
          {t(`clientStories.clientStoriesTextSection.subtitle`)}
        </Subtitle>

        <LastParagraph>
          <GrayRectangle />
          {t(`clientStories.clientStoriesTextSection.lastparagraph`)}
        </LastParagraph>
      </WrapContainer>
    </Container>
  );
};
