import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  StyledToolbar,
  DesktopBox,
  HeaderContainer,
  Navbar,
  SearchAndContact,
  //Search,
  ContactButton,
  LanguageButton,
  LanguageMenu,
  LanguageMenuItem,
  MobileBox,
  MenuButton,
  MobileMenu,
  MobileMenuItem,
  MobileMenuTitle,
  CloseButton,
  LanguageDrawer,
} from "./styles";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/check-mark.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg";
//import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/comment.svg";
import { LanguageContext } from "../../context/language";
import ReactGA from "react-ga4";

import { useTranslation } from "react-i18next";
import { useLanguage } from "../../shared/hooks/useLanguage";
import { MenuNavLink } from "../MenuNavLink";
import { useMenuPages } from "../../shared/hooks/useMenuPages";

const email = "contato@labsit.io";

export const Header = () => {
  const { t, i18n } = useTranslation();

  const { pages } = useMenuPages();

  const { languages, currenti18n } = useLanguage();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng.abbrev);
    handleCloseLanguageMenu(lng);
  };

  const [anchorElLanguageMenu, setAnchorElLanguageMenu] = useState();
  const [drawerIsOpen, setDrawerIsOpen] = useState();
  const [anchorElNav, setAnchorElNav] = useState();
  const [currentLanguage, setCurrentLanguage] = useContext(LanguageContext);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    ReactGA.event({
      category: 'User',
      action: 'conversion'
    });
    setAnchorElNav(null);
  };

  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguageMenu(event.currentTarget);
  };

  const handleCloseLanguageMenu = (language) => {
    setCurrentLanguage(language);
    setAnchorElLanguageMenu(null);
  };

  const toggleDrawer = (language) => {
    if (language) {
      i18n.changeLanguage(language.abbrev);
      setCurrentLanguage(language);
    }
    setDrawerIsOpen(!drawerIsOpen);
  };

  return (
    <>
      <HeaderContainer id="header-container">
        <StyledToolbar>
          <DesktopBox>
            <Link to={"/"}>
              <Logo />
            </Link>
            <Navbar>
              {pages?.map((page) => (
                <MenuNavLink key={`desktop-${page.id}`} page={page} t={t} />
              ))}
            </Navbar>
            <SearchAndContact>
              {/*<Search>
                <input placeholder={t("navigation.searchLabel")} />
                <SearchIcon />
              </Search>*/}
              <ContactButton href={`mailto:${email}`} target="_blank" onClick={() => {
                ReactGA.event({
                  category: 'User',
                  action: 'conversion'
                })
              }}>
                <CommentIcon />
                <span>{t("navigation.contactLabel")}</span>
              </ContactButton>
              <LanguageButton onClick={handleOpenLanguageMenu}>
                <GlobeIcon />
                <span>{t("language")}</span>
              </LanguageButton>
              <LanguageMenu
                anchorEl={anchorElLanguageMenu}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorElLanguageMenu)}
                onClose={() => changeLanguage(currentLanguage)}
              >
                {languages.map((language) => (
                  <LanguageMenuItem
                    key={language.label}
                    className={
                      language.languageVariations.includes(currenti18n)
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      changeLanguage(language);
                    }}
                  >
                    {language.label}
                    <CheckMarkIcon />
                  </LanguageMenuItem>
                ))}
              </LanguageMenu>
            </SearchAndContact>
          </DesktopBox>
          <MobileBox>
            <Logo />
            <div>
              {/*<Search>
                <input placeholder={t("navigation.searchLabel")} />
                <SearchIcon />
                  </Search>*/}
              <MenuButton onClick={handleOpenNavMenu}>
                <MenuIcon />
              </MenuButton>
              <MobileMenu
                id="mobile-menu"
                keepMounted={true}
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                onClose={handleCloseNavMenu}
              >
                <MobileMenuTitle>
                  <h1>Menu</h1>
                  <CloseButton onClick={handleCloseNavMenu}>
                    <CrossIcon />
                  </CloseButton>
                </MobileMenuTitle>
                {pages?.map((page) => (
                  <MobileMenuItem key={page.id} onClick={handleCloseNavMenu}>
                    <MenuNavLink key={`mobile-${page.id}`} page={page} t={t} />
                  </MobileMenuItem>
                ))}
                <footer>
                  <ContactButton
                    onClick={handleCloseNavMenu}
                    href={`mailto:${email}`}
                    target="_blank"
                  >
                    <CommentIcon />
                    <span>{t("navigation.contactLabel")}</span>
                  </ContactButton>
                  <LanguageButton
                    onClick={() => {
                      toggleDrawer();
                    }}
                  >
                    <GlobeIcon />
                    <span>{t("language")}</span>
                  </LanguageButton>
                  <LanguageDrawer
                    anchor="bottom"
                    open={drawerIsOpen}
                    onClose={() => {
                      toggleDrawer();
                    }}
                  >
                    <h1>{t("navigation.languageSettingTitle")}</h1>
                    {languages.map((language) => (
                      <LanguageMenuItem
                        key={language.label}
                        className={
                          currenti18n === language.abbrev ? "active" : ""
                        }
                        onClick={() => {
                          toggleDrawer(language);
                        }}
                      >
                        {language.label}
                        <CheckMarkIcon />
                      </LanguageMenuItem>
                    ))}
                  </LanguageDrawer>
                </footer>
              </MobileMenu>
            </div>
          </MobileBox>
        </StyledToolbar>
      </HeaderContainer>
    </>
  );
};
