import { Link } from "react-router-dom";
import {
  Container,
  ImageContainer,
  Title,
  ContentContainer,
  ContentTitle,
  ContentText,
  CallToActionButton,
} from "./styles";

export const Case = ({
  title,
  content,
  backgroundImage,
  reversed,
  sx,
  link,
}) => {
  return (
    <Container sx={sx} reversed={reversed}>
      <ImageContainer image={backgroundImage} reversed={reversed}>
        <div>
          <Title variant="h1">{title}</Title>
        </div>
      </ImageContainer>
      <ContentContainer>
        <ContentTitle variant="h1">{content.title}</ContentTitle>
        <ContentText variant="body2">{content.body}</ContentText>
        <Link to={link}>
          <CallToActionButton>{content.action.label}</CallToActionButton>
        </Link>
      </ContentContainer>
    </Container>
  );
};
