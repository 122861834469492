import {
  Container,
  ImagePlaceHolder,
  Title,
  WrapContainer,
  StyledImageListItem,
  GradientContainer,
  GradientRectangle
} from "./styles";
import banner01 from "../../../assets/images/privacy-policy-02.png";

import { useTranslation } from "react-i18next";



export const Banner = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledImageListItem>
        <ImagePlaceHolder src={banner01} alt={"banner"} />
      </StyledImageListItem>

      <WrapContainer>
      <GradientContainer>
        <Title>{t(`privacyPolicy.title`)}</Title>
        <GradientRectangle />
        </GradientContainer>
      </WrapContainer>
    </Container>
  );
};