export const englishLanguageVariations = ["en", "en-US", "en-GB"];
export const portugueseLanguageVariations = [
  "pt",
  "pt-AO",
  "pt-BR",
  "pt-CV",
  "pt-GW",
  "pt-MZ",
  "pt-ST",
  "pt-TL",
  "pt-PT"
];

export const languages = [
  {
    abbrev: "pt-PT",
    label: "Português - PT",
    languageVariations: ["pt-PT"],
  },
  {
    abbrev: "pt-BR",
    label: "Português - BR",
    languageVariations: ["pt-BR"],
  },
  {
    abbrev: "en",
    label: "English",
    languageVariations: englishLanguageVariations,
  },
];

export const userLocale = navigator?.languages?.length
  ? navigator.languages[0]
  : navigator.language;

export const availableLanguages = englishLanguageVariations.concat(
  portugueseLanguageVariations
);
