import {
  Container,
  WrapContainer,
  FirstParagraph,
  SecondParagraph,
  LastParagraph,
} from "./styles";
import { useTranslateGetObject } from "../../../shared/hooks/useTranslateGetObject";

export const ThirdTextSection = () => {
  const firstParagraphTexts = useTranslateGetObject("about.thirdTextSection.firstParagraph");
  const secondParagraphTexts = useTranslateGetObject("about.thirdTextSection.secondParagraph");
  const lastParagraphTexts = useTranslateGetObject("about.thirdTextSection.lastParagraph");

  return (
    <Container>
      <WrapContainer>
        <FirstParagraph component={"span"} variant={"body2"}>
          {firstParagraphTexts.map((text, index) => (
            <div key={`first-paragraph-${index}`}>{text}</div>
          ))}
        </FirstParagraph>
        <SecondParagraph component={"span"} variant={"body2"}>
          {secondParagraphTexts.map((text, index) => (
            <div key={`second-paragraph-${index}`}>{text}</div>
          ))}
        </SecondParagraph>
        <LastParagraph component={"span"} variant={"body2"}>
          {lastParagraphTexts.map((text, index) => (
            <div key={`last-paragraph-${index}`}>{text}</div>
          ))}
        </LastParagraph>
      </WrapContainer>
    </Container>
  );
};
