import { styled } from '@mui/system';
import { Paragraph } from '../../../components/Paragraph';

export const Container = styled("section")({
  width: "100%",
});

export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  alignItems: "center",

  [theme.breakpoints.down("sm")] : {
    padding: "0 1.5rem",
    fontSize: "2rem",
    
    paddingRight: "0"
  }
}));

export const Title = styled(Paragraph)(({ theme }) => ({
  fontSize: "2rem",
  marginTop: "5rem",
  marginBottom: "1.5rem",
  textAlign: "left",

  [theme.breakpoints.down("sm")] : {
    fontSize: "2rem",
    marginBottom: "1.5rem",
  }
}));

export const TextParagraph = styled(Paragraph)(({ theme }) => ({
  fontSize: "1rem",
  marginBottom: "1.5rem",
  width: "58.16rem",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1rem",
    marginBottom: "1.5rem",
    width: "20.4375rem",
  }
}));

export const SmallTextParagraph = styled(Paragraph)(({ theme }) => ({
  fontSize: "0.9rem",
  marginBottom: "1.5rem",
  width: "58.16rem",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1rem",
    marginBottom: "1.5rem",
    width: "20.4375rem",
  }
}));

export const Subtitle = styled(Paragraph)(({ theme }) => ({
  fontSize: "1.375rem",
  marginBottom: "1.5rem",
  width: "58.16rem",
  textAlign: "center",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1.375rem",
    marginBottom: "1.5rem",
    width: "20.4375rem",
    textAlign: "left"
  }
}));

export const SmallSubtitle = styled(Paragraph)(({ theme }) => ({
  fontSize: "1rem",
  marginBottom: "1.5rem",
  width: "58.16rem",
  
  [theme.breakpoints.down("sm")] : {
    fontSize: "1.375rem",
    marginBottom: "1.5rem",
    width: "20.4375rem",
    textAlign: "left"
  }
}));
