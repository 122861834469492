import { styled } from '@mui/system';
import { Paragraph } from '../Paragraph';

export const Container = styled("section")(({ theme }) => ({
  margin: "0 auto",
  padding: "0 5rem",

  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1.5rem"
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: "120rem"
  }
}));

export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  alignItems: "center",

  [theme.breakpoints.down("sm")] : {
    padding: "0 1.5rem",
    fontSize: "2rem",
    
    paddingRight: "0"
  }
}));

export const Title = styled(Paragraph)(({ theme }) => ({
  fontSize: "2rem",
  marginTop: "5rem",
  marginBottom: "1.5rem",
  textAlign: "left",

  [theme.breakpoints.down("sm")] : {
    fontSize: "2rem",
    marginBottom: "1.5rem",
  }
}));

export const FirstParagraph = styled(Paragraph)(({ theme }) => ({
  fontSize: "1rem",
  marginBottom: "1.5rem",
  width: "58.16rem",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1rem",
    marginBottom: "1.5rem",
    width: "20.4375rem",
  }
}));

export const RetanguleParagraph = styled(Paragraph)(({ theme }) => ({
  display: "flex",
  position: "relative",
  fontSize: "1.125rem",
  marginBottom: "1.5rem",
  width: "53.75rem",

  [theme.breakpoints.down("sm")] : {
      fontSize: "1.125rem",
      marginBottom: "1.5rem",
      textAlign: "left",
      width: "20.4375rem",
  }
}));

export const Subtitle = styled(Paragraph)(({ theme }) => ({
  fontSize: "1.375rem",
  marginBottom: "1.5rem",
  width: "58.16rem",
  textAlign: "center",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1.375rem",
    marginBottom: "1.5rem",
    width: "20.4375rem",
    textAlign: "left"
  }
}));

export const LastParagraph = styled(Paragraph)(({ theme }) => ({
  fontSize: "1rem",
  marginBottom: "1.5rem",
  width: "58.16rem",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1rem",
    marginBottom: "1.5rem",
    width: "20.4375rem",
  }
}));

export const GrayRectangle = styled("div")(({ theme }) => ({
  background: "gray",
  width: "8px",
  height: "118px",
  margin: "0 2.5rem 0 0",
  marginTop: "auto",

  [theme.breakpoints.down("sm")] : {
    width: "20px",
    height: "270px",
  }
}));