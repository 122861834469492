import { PageContainer, StyledGradientText, StyledGridView } from "./styles";
import { useTranslation } from "react-i18next";
import { useTranslateGetObject } from "../../../shared/hooks/useTranslateGetObject";

export const TextSection = () => {
  const { t } = useTranslation();
  const grid = useTranslateGetObject("career.textSection.grid");

  return (
    <PageContainer>
      <div>
        <p>{t("career.textSection.firstParagraph")}</p>
        <p>{t("career.textSection.secondParagraph")}</p>
        <p>{t("career.textSection.thirdParagraph")}</p>
      </div>
      <StyledGradientText>{t("career.textSection.gradientTitle")}</StyledGradientText>
      <StyledGridView>
        {grid.map((item, index) => (
          <div key={index}>
            <span className="title">{item.title}</span>
            <span className={"sub-title ".concat(item.style)}>{item.subTitle}</span>
            <span>{item.description}</span>
          </div>
        ))}
      </StyledGridView>
    </PageContainer>
  );
};
