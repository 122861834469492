import { styled } from "@mui/system";

export const Container = styled("section")({
  width: "100%",
});

export const PlayerDiv = styled("div")({
  width: "100%",
  margin: "0 auto",
  padding: "0",
  position: "relative",
});
