import { GradientText } from "../../components/GradientText";
import { styled } from "@mui/system";

export const PageContainer = styled("main")(({ theme }) => ({
  padding: "0 10rem",
  maxWidth: "120rem",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("xl")]: {
    padding: "0 5rem"
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 1.5rem"
  }
}));

export const GradientTitle = styled(GradientText)(({ theme }) => ({
  fontSize: "3.75rem",
  fontWeight: "500",
  lineHeight: "1.5",
  width: "23.875rem",
  margin: "5rem 0rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "center",
    fontSize: "2rem",
    marginTop: "2rem"
  }
}));

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "5rem"
});
