import { styled } from '@mui/system';
import { GradientText } from "../../../components/GradientText"
import { Paragraph } from '../../../components/Paragraph';

export const Container = styled("section")({
  width: "100%",
});

export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",


  [theme.breakpoints.down("sm")] : {
    padding: "0 1.5rem",
    fontSize: "2rem",
    
    paddingRight: "0"
  }
}));

export const GradientTitle = styled(GradientText, Paragraph)(({ theme }) => ({
  fontSize: "3.75rem",
  marginBottom: "1rem",
  fontWeight: "500",
  [theme.breakpoints.down("sm")] : {
    fontSize: "2rem",
    margin: "0"
  }
}));

export const Subtitle = styled(Paragraph)(({ theme }) => ({
  fontSize: "1.5rem",
  marginBottom: "2.5rem",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1.375rem"
  }
}));

export const FirstParagraph = styled(Paragraph)(({ theme }) => ({}));

export const SecondParagraph = styled(Paragraph)(({ theme }) => ({}));

export const ThirdParagraph = styled(Paragraph)(({ theme }) => ({}));

export const LastParagraph = styled(Paragraph)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "500",
  marginBottom: "5rem",
  textAlign: "center",

  [theme.breakpoints.down("sm")] : {
    fontSize: "1.375rem",
    marginBottom: "2.5rem",
    textAlign: "left",
  }
}));
