import { Masonry } from "@mui/lab";
import { Paper } from "@mui/material";
import { styled } from "@mui/system";

export const Container = styled("section")(({ theme }) => ({
  width: "100%",
  margin: "5rem auto",
}));

export const StyledMasonry = styled(Masonry)(({ theme }) => ({
  "div:nth-of-type(2), div:nth-of-type(4)": {
    marginTop: "5rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "calc(16px / 2)",
    },
  },
  div: {
    aspectRatio: "1 / 2",
  },
  "div:nth-of-type(2)": {
    aspectRatio: "1 / 1",
    [theme.breakpoints.down("sm")]: {
      aspectRatio: "1 / 2",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "2.5rem",
    },
  },
  "div:nth-of-type(8)": {
    aspectRatio: "1 / 2.5",
    [theme.breakpoints.down("sm")]: {
      aspectRatio: "1 / 2",
    },
  },
}));

export const StyledPaper = styled(Paper)(({ background }) => ({
  width: "100%",
  background: `url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  borderRadius: "16px",
}));
