import { PageContainer, StyledVideoDiv } from "./styles";
import { Cards } from "../Cards";
import { TextSection } from "../TextSection";
import { VideoBanner } from "../../../components/VideoBanner";

export const StoriesAction = () => {
  const urlVideo = "https://www.youtube.com/watch?v=Y2D9eXeOwfc";

  return (
    <PageContainer>
      <StyledVideoDiv>
        <VideoBanner url={urlVideo} />
      </StyledVideoDiv>
      <TextSection />
      <Cards />
    </PageContainer>
  );
};
