import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  ContainerHuman,
  Title,
  StyledButton,
  TextContainer,
  ContainerHumans,
} from "./styles";
import { Card } from "../../../components/CardHuman";
import { randomNumberInRange } from "../../../shared/utils/mathFunctions";
import { useTranslateGetObject } from "../../../shared/hooks/useTranslateGetObject";

export const Banner = () => {
  const { t } = useTranslation();
  const [styleHuman, setStyleHuman] = useState("visible");
  const [styleHumans, setStyleHumans] = useState("invisible");

  let humans = useTranslateGetObject("humans");
  
  const randomIndex = randomNumberInRange(0, humans.length - 1);
  const currentHuman = humans[randomIndex];

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const element = document.getElementById("containerHumans");

  const mouseDownHandler = function (e) {
    if (element != null) {
      element.style.cursor = "grabbing";
      element.style.userSelect = "none";
      pos = {
        left: element.scrollLeft,
        top: element.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };
    }

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    if (element != null) {
      element.scrollTop = pos.top - dy;
      element.scrollLeft = pos.left - dx;
    }
  };

  const mouseUpHandler = function () {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
    
    if (element != null) {
      element.style.cursor = "grab";
      element.style.removeProperty("user-select");
    }
  };

  document.addEventListener("mousedown", mouseDownHandler);

  const handleClick = () => {
    setStyleHuman("invisible");
    setStyleHumans("visible");
  };

  return (
    <Container>
      <ContainerHuman className={styleHuman}>
        <Card
          image={currentHuman.image}
          name={currentHuman.name}
          linkedin={currentHuman.linkedIn}
          job={currentHuman.job}
          link={currentHuman.link}
        />
        <div>
          <TextContainer>
            <Title>{t(`career.banner.title`)}</Title>
            <p>{t(`career.banner.description`)}</p>
          </TextContainer>
          <StyledButton className="no-transition" onClick={handleClick}>
            {t(`career.banner.seeAllButton`)}
          </StyledButton>
        </div>
      </ContainerHuman>
      <ContainerHumans id="containerHumans" className={styleHumans}>
        {humans.map((human, index) => (
          <Card
            key={index}
            image={human.image}
            name={human.name}
            linkedin={human.linkedIn}
            job={human.job}
            link={human.link}
          />
        ))}
      </ContainerHumans>
    </Container>
  );
};
