import { StyledDiv } from './styles';
import { useTranslation } from 'react-i18next';
import { useTranslateGetObject } from '../../shared/hooks/useTranslateGetObject';

import { MenuNavLink } from '../MenuNavLink';

import { useMenuPages } from '../../shared/hooks/useMenuPages';

export const LinkNavigation = () => {
  const { t } = useTranslation();

  const { aboutUs, insights, siteInformations } = useMenuPages();

  const offers = useTranslateGetObject('offers');

  const offersMoveLink = offers.filter(
      (offer) => offer.category === 'offer-that-move-you'
  ).map((offer) => ({
    id: offer.slug,
    to: offer.link,
    translation: offer.title,
  }));

  const offersLeverageLink = offers.filter(
      (offer) => offer.category === 'offer-that-leverage-you'
  ).map((offer) => ({
        id: offer.slug,
        to: offer.link,
        translation: offer.title,
      }));

  return (
    <>
      {aboutUs?.length > 0 && (
        <StyledDiv key={'about-us'} className="element-link">
          <label>{t('navigation.label.aboutUs')}</label>
          {aboutUs.map((page) => (
            <StyledDiv key={`about-us-${page.id}`}>
              <MenuNavLink key={`about-us-${page.id}`} page={page} t={t} />
            </StyledDiv>
          ))}
        </StyledDiv>
      )}
      {offersMoveLink?.length > 0 && (
        <StyledDiv key={'offers-move'} className="element-link offers">
          <label>{t('navigation.label.offersMove')}</label>
          {offersMoveLink.map((page) => (
              <StyledDiv key={`offers-${page.id}`}>
                  <MenuNavLink key={`offers-${page.id}`} page={page} t={t} />
              </StyledDiv>
          ))}
        </StyledDiv>
      )}
      {offersLeverageLink?.length > 0 && (
          <StyledDiv key={'offers-leverage'} className="element-link offers">
            <label>{t('navigation.label.offersLeverage')}</label>
            {offersLeverageLink.map((page) => (
                <StyledDiv key={`offers-${page.id}`}>
                    <MenuNavLink key={`offers-${page.id}`} page={page} t={t} />
                </StyledDiv>
            ))}
          </StyledDiv>
      )}
      {insights?.length > 0 && (
        <StyledDiv key={'insights'} className="element-link">
          <label>{t('navigation.label.insights')}</label>
          {insights.map((page) => (
            <StyledDiv key={`insights-${page.id}`}>
              <MenuNavLink key={`insights-${page.id}`} page={page} t={t} />
            </StyledDiv>
          ))}
        </StyledDiv>
      )}
      {siteInformations?.length > 0 && (
        <StyledDiv key={'site-information'} className="element-link">
          <label>{t('navigation.label.siteInformations')}</label>
          {siteInformations.map((page) => (
            <StyledDiv key={`site-information-${page.id}`}>
              <MenuNavLink
                key={`site-information-${page.id}`}
                page={page}
                t={t}
              />
            </StyledDiv>
          ))}
        </StyledDiv>
      )}
    </>
  );
};
