import { styled } from "@mui/system"
import { Card, CardContent, Link } from "@mui/material"

export const StyledCard = styled(Card)(({ background }) => ({
  display: "flex",
  alignItems: "flex-end",
  height: "100%",
  width: "100%",
  maxWidth: "500px",
  minWidth: "300px",
  aspectRatio: "2.5 / 3",
  borderRadius: "40px",
  fontWeight: "700",
  fontSize: "32px",
  lineHeight: "150%",
  color: "#FFFFFF",
  background: "url(" + background +")",
  backgroundSize: "cover",
  backgroundColor: "black",
  transition: "all 0.3s",
  ":hover": {
    transform: "scale(1.1)",
  }
}))

export const StyledCardContent = styled(CardContent)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  "div": {
    display: "flex",
    flexDirection: "column"
  },
  "span": {
    fontSize: "18px"
  }
})

export const StyledLink = styled(Link)({
  display: "flex",
  alignItems: "center",
})
