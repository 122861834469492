import { Typography, ButtonBase } from "@mui/material"
import { styled } from "@mui/system"

export const Container = styled("div")(({theme, reversed}) =>({
  background: "#0f0f0f",
  color: "#FFF",
  display: "flex",
  flexDirection: (reversed) ? "row-reverse": "row",
  justifyContent: "space-around",
  flex: "1",
  alignItems: "center",
  minHeight: "42.5rem",
  gap: "10rem",
  [theme.breakpoints.down("xl")]: {
    gap: "10rem",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
    gap: "1.5rem",
    minHeight: "100vh",
    borderRadius: "40px",
    margin: "0",
  },
}))

export const ImageContainer = styled("div")(({image, theme, reversed}) => ({
  background: `url(${image})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "fit",
  display: "flex",
  alignSelf: "stretch",
  alignItems: "center",
  justifyContent: (reversed) ? "flex-start": "flex-end",
  backgroundPosition: "center",
  width: "42.5rem",
  [theme.breakpoints.down("md")]: {
    height: "35rem",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  "& > div": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: (reversed) ? "flex-start" : "flex-end",
    position: "relative",
    right: (reversed) ? "80px" : "-80px",
    [theme.breakpoints.down("sm")]: {
      height: "35rem",
      alignItems: "center",
      justifyContent: "center",
      right: "0",
    },
    "&::after": {
      content: "' '",
      background: "linear-gradient(90deg, #FF0097 0%, #0069E6 100%)",
      width: "10rem",
      height: "3px",
      padding: "0",
      marginTop: "1.5rem",
      [theme.breakpoints.down("sm")]: {
        marginTop: "1rem",
      }
    }
  }
}))

export const Title = styled(Typography)(({ theme }) =>({
  lineHeight: "1.5",
  fontWeight: "500",
  fontSize: "3.75rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem"
  }
}))

export const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: "1.5rem",
  padding: "0",
}))

export const ContentTitle = styled(Typography)({
  lineHeight: "1.5",
  fontWeight: "500",
  fontSize: "2rem",
  padding: "0",
  margin: "0"
})

export const ContentText = styled(Typography)(({ theme }) => ({
  lineHeight: "1.5",
  flex: "1",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  },
}))

export const CallToActionButton = styled(ButtonBase)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  color: "#0f0f0f",
  borderRadius: "6.25rem",
  padding: "0 1rem",
  width: "8.4375rem",
  height: "3rem",
  fontWeight: "700",
})

