import { useTranslation } from "react-i18next";
import { availableLanguages, languages } from "../config/languages";

export const useLanguage = () => {
  const { i18n } = useTranslation();

  const currenti18n = i18n.language;

  return { languages, currenti18n, availableLanguages };
};
