import { CertificationCard } from '../../../components/CertificationCard'
import { useCertifications } from '../../../shared/hooks/useCertifications'

import { Container } from "./styles"

export const Certifications = () => {
  const [certifications] = useCertifications()

  return (
    <Container>
        {certifications.map(cert => <CertificationCard key={cert.id} src={cert.image} alt={cert.alt} />)}
    </Container>
  )
}