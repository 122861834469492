import { ArrowForwardOutlined } from "@mui/icons-material";
import { Link } from "@mui/material";
import { styled } from "@mui/system";
import { GradientText } from "../../../components/GradientText";

export const Container = styled("section")({
  maxWidth: "120rem",
  margin: "80px 0",
  display: "grid",
  gridTemplateColumns: "1fr auto",
  ".fade-enter": {
    opacity: 0,
    transform: "scale(0)",
  },
  ".fade-enter-active": {
    opacity: 1,
    transform: "scale(1)",
  },
  ".fade-exit": {
    opacity: 1,
    transform: "scale(1)",
  },
  ".fade-exit-active": {
    opacity: 0,
    transform: "scale(0)",
  },
  ".fade-enter-active, .fade-exit-active": {
    transition: "opacity 200ms, transform 500ms",
  },
});

export const ContainerHuman = styled("section")(({ theme }) => ({
  maxWidth: "120rem",
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "4rem",
  [theme.breakpoints.down("lg")]: {
    gap: "2rem",
    flexDirection: "column",
  },
}));

export const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minHeight: "350px",
  width: "100%",
}))

export const TextContainer = styled("section")(({ theme }) => ({
  display: "flex",
  flex: "1",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  ".name": {
    fontSize: "3.75rem",
    fontWeight: "500",
    lineHeight: "150%",
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
  },
  ".job": {
    fontSize: "2rem",
    fontWeight: "500",
    lineHeight: "150%",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.375rem",
    },
  },
  ".description": {
    margin: "40px 0",
    fontSize: "1.25rem",
    maxWidth: ""
  },
}));

export const AnotherHuman = styled(GradientText)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  fontSize: "45px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "26px",
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  ":hover": {
    cursor: "pointer",
  },
}));

export const StyledArrowForwardOutlined = styled(ArrowForwardOutlined)({
  color: "#0069E6",
});
