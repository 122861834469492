import { Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Wrapper } from "./styles";

export const MenuNavLink = ({ page, t, target = "_blank" }) => {
  return (
    <Wrapper>
      {page.externalLink ? (
        <Link key={page.id} target={target} href={page.to}>
          {t(page.translation)}
        </Link>
      ) : (
        <NavLink key={page.id} to={page.to}>
          {t(page.translation)}
        </NavLink>
      )}
    </Wrapper>
  );
};
