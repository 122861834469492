import { PageContainer, PillarSection } from './styles';
import { BannerCarousel } from './BannerCarousel';
import { Pillar } from '../../components/Pillar';
//import { Cases } from "./Cases";
import { Customers } from './Customers';
import { Sale } from './Sale';
import { WorkWithUs } from './WorkWithUs';
import { Offer } from './Offer';

export const Home = () => {
  return (
    <PageContainer>
      <BannerCarousel />
      <PillarSection>
        <Pillar />
      </PillarSection>
      <Offer />
      {/*<Cases />*/}
      <Customers />
      <Sale />
      <WorkWithUs />
    </PageContainer>
  );
};
