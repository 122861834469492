import { PageContainer } from "./styles";
import { Banner } from "./Banner";
import { TextSection } from "./TextSection";


export const PrivacyPolicy = () => {

  return (
    <PageContainer>
      <Banner />
      <TextSection />
    </PageContainer>
  );
};
