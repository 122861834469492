import { styled } from "@mui/system";
import { GradientText } from "../../../components/GradientText";

export const PageContainer = styled("section")(({ theme }) => ({
  padding: "5rem 5rem",
  gap: "80px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    padding: "5rem 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "2.5rem 0",
    gap: "40px",
  },
}));

export const StyledGradientText = styled(GradientText)(({ theme }) => ({
  fontSize: "45px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "32px",
  },
}));

export const StyledGridView = styled("div")(({ theme }) => ({
  display: "grid",
  gap: "80px",
  columnSpan: 2,
  gridTemplateColumns: "1fr 1fr",
  div: {
    display: "flex",
    flexDirection: "column",
  },
  ".title": {
    fontSize: "32px",
  },
  ".sub-title": {
    fontSize: "22px",
  },
  ".pink": {
    color: "#FF0097",
  },
  ".blue": {
    color: "#0069E6",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    gap: "40px",
    ".title": {
      fontSize: "26px",
    },
    ".sub-title": {
      fontSize: "18px",
    },
  },
}));
