import { 
  Container, 
  WrapContainer, 
  GradientTitle, 
  FirstParagraph, 
  SecondParagraph, 
  ThirdParagraph,
  LastParagraph
  } from "./styles"

import { useTranslation } from "react-i18next";

export const SecondTextSection = ( ) => {
  const { t } = useTranslation();

  return (
    <Container>      
      <WrapContainer>
        <GradientTitle>
          {t(`about.secondTextSection.gradientTitle`)}
        </GradientTitle>
        <FirstParagraph>
          {t(`about.secondTextSection.firstParagraph`)}
        </FirstParagraph>
        <SecondParagraph>
          {t(`about.secondTextSection.secondParagraph`)}  
        </SecondParagraph>
        <ThirdParagraph>
          {t(`about.secondTextSection.thirdParagraph`)}
        </ThirdParagraph>
        <LastParagraph>
          {t(`about.secondTextSection.lastParagraph`)}
        </LastParagraph>
      </WrapContainer> 
    </Container>
  )
}