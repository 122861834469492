import { styled, keyframes } from "@mui/system";

export const animation = keyframes({
  "0%": {
    transform: "translateX(-100%)",
  },
  "100%": {
    transform: "translateX(50%)",
  },
});

export const animation2 = keyframes({
  "0%": {
    transform: "translateX(-200%)",
  },
  "100%": {
    transform: "translateX(0%)",
  },
});

export const Container = styled("div")({
  display: "flex",
  overflow: "hidden",
});

const BaseWrapperText = styled("div")({
  display: "flex",
  height: "150px",
  width: "4950px",
  position: "relative",
  left: "0",
  span: {
    userSelect: "none",
    display: "inline-block",
    margin: "0 5rem",
  },
});

export const WrapperText = styled(BaseWrapperText)(({ duration }) => ({
  animation: `${animation} ${duration}ms linear infinite reverse`,
}));

export const WrapperText2 = styled(BaseWrapperText)(({ duration }) => ({
  animation: `${animation2} ${duration}ms linear infinite ${
    duration / 2
  }ms reverse`,
}));
