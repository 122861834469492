import { Grid } from "@mui/material";
import { Item } from "./styles";

export const CustomersGrid = ({ customer }) => {
  const urlImage = "/images/customers";

  return (
    <Grid
      item
      xs={6}
      xl={2.4}
      md={2.4}
      sm={4}
      alignItems={"stretch"}
      alignContent={"stretch"}
      key={customer.id}
    >
      <Item>
        <img
          src={`${urlImage}/${customer.image}`}
          srcSet={`${urlImage}/${customer.image}`}
          alt={`${customer.name}`}
          loading="lazy"
        />
      </Item>
    </Grid>
  );
};
