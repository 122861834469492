import { styled } from "@mui/system";

export const WrapperOverlay = styled("div")({
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  position: "fixed",
  background: "#222",
});

export const WrapperOverlayInner = styled("div")({
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  position: "absolute",
});

export const WrapperOverlayContent = styled("div")({
  left: "50%",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  svg: {
    color: "#FF00979C",
  },
});
