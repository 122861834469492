import axios from 'axios';

export const getJobs = async () => {
  let response = await axios.get('https://bgweyw31ag.execute-api.us-east-1.amazonaws.com/staging')
    .then((response) => response)
    .catch(function (error) {
      console.error(error);
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      }
    });
  return response;
};
