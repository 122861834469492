import { RollingText } from "../../../components/RollingText";
import { useTranslateGetObject } from "../../../shared/hooks/useTranslateGetObject";
import { Container } from "./styles";
export const ScrollInteraction = () => {
  const text = useTranslateGetObject("rollingText");

  return (
    <Container>
      <RollingText duration={45000}>
        {text.firstLine.map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </RollingText>
      <RollingText duration={42000}>
        {text.secondLine.map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </RollingText>
    </Container>
  );
};
