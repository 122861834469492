import { styled } from "@mui/system";

export const PageContainer = styled("section")(({ theme }) => ({
  gap: "80px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up("md")]: {
    padding: "0 5rem 5rem 5rem",
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: "0 0 5rem 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 0 2.5rem 0",
    gap: "40px",
  },
}));

export const StyledGrid = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gap: "24px",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
}));

export const Cell = styled("div")(
  ({ span = 1, spanDirection = "horizontal" }) => {
    return spanDirection === "vertical"
      ? { gridRow: `span ${span}` }
      : { gridColumn: `span ${span}` };
  }
);

export const StyledVideoDiv = styled("div")(({ theme }) => ({
  borderRadius: "24px",
}));
