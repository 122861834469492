export const HomeLinks = [
  {
    id: 1,
    to: "/",
    translation: "navigation.menu.home",
    externalLink: false,
    visible: true,
  },
];

export const AboutUsLinks = [
  {
    id: 2,
    to: "/about",
    translation: "navigation.menu.about",
    externalLink: false,
    visible: true,
  },
  {
    id: 4,
    to: "/career",
    // to: "https://labsit.gupy.io",
    translation: "navigation.menu.career",
    externalLink: false,
    visible: true,
  },
];

export const ServicesLinks = [
  {
    id: 3,
    to: "/stories",
    translation: "navigation.menu.stories",
    externalLink: false,
    visible: false,
  },
];

export const InsightsLinks = [
  {
    id: 5,
    to: "/insights",
    translation: "navigation.menu.insights",
    externalLink: false,
    visible: false,
  },
];

export const SiteInformationsLinks = [
  {
    id: 6,
    to: "/privacypolicy",
    translation: "navigation.menu.privacypolicy",
    externalLink: false,
    visible: true,
  },
];
