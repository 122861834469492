import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { GradientText } from "../../../components/GradientText";

export const Container = styled("section")({
  display: "flex",
  flexDirection: "row",
  margin: "40px 0 0 0",
  ".visible": {
    position: "relative",
    visibility: "visible",
    opacity: 1,
    transform: "scale(1)",
  },
  ".invisible": {
    position: "absolute",
    visibility: "hidden",
    opacity: 0,
    transform: "scale(0)",
  },
});

export const ContainerHuman = styled("section")(({ theme }) => ({
  maxWidth: "120rem",
  width: "100%",
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "space-around",
  gap: "4rem",
  transition: "transform 0.5s, visibility 0.5s, opacity 0.5s linear",
  [theme.breakpoints.down("lg")]: {
    gap: "2rem",
    flexDirection: "column",
  },
}));

export const ContainerHumans = styled("section")(({ theme }) => ({
  maxWidth: "120rem",
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  gap: "4rem",
  overflowX: "auto",
  overflowY: "hidden",
  transitionDelay: "1s",
  transition: "transform 0.5s, visibility 0.2s, opacity 0.5s ease-in",
  [theme.breakpoints.down("lg")]: {
    gap: "2rem",
  },
}));

export const TextContainer = styled("section")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "left",
  ".name": {
    fontSize: "60px",
    fontWeight: "500",
    lineHeight: "150%",
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
  },
  ".job": {
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "150%",
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
    },
  },
  ".description": {
    margin: "40px 0",
  },
}));

export const Title = styled(GradientText)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  fontSize: "60px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "45px",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  background: "#FF0097",
  width: "100%",
  maxWidth: "250px",
  borderRadius: "100px",
  textDecoration: "none",
  color: "#FFFFFF",
  ":hover": {
    background: "#FF007F",
  },
}));
