import { GlobalStyles } from '@mui/material';

export const Global = () => {
  return <GlobalStyles styles={
    { 
      body: {
        fontFamily: "DM Sans, sans-serif",
        boxSizing: "border-box",
        background: "#0F0F0F",
        color: "#FFF",
        minWidth: '375px',
      },
      "h1, h2, h3, input, a, p": {
        color: "#FFF",
        fontFamily: "DM Sans, sans-serif",
      },
      "::-webkit-scrollbar": {
        width: "0.8rem",
        height: "0.5rem",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#000000",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#FFFFFF",
        borderRadius: "9999px",
      },
    }
  } />
};
