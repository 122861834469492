import { styled } from '@mui/system'
import { Typography } from "@mui/material"

export const Container = styled("div")(({ theme }) => ({
  display: "flex",  
  gap: "2.5rem",
  alignItems: "center",
  padding: "1.5rem 2.5rem",
  border: "1px solid #3a3a3a",
  borderRadius: "24px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column"
  },
}))
export const Header = styled("header")({
  width: "10rem",
  display: "flex",
  gap: "0.5rem",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
})
export const Avatar = styled("div")(({ img }) => ({
  background: `url(${img})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  width: "5rem",
  height: "5rem",
  borderRadius: "50%",
  overflow: "hidden"
}))
export const Title = styled(Typography)({
  fontSize: "22px",
  lineHeight: "1.5",
  fontWeight: "700"
})
export const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  flex: "1",
  width: "100%"
})

export const Description = styled(Typography)({
  fontSize: "1.125rem"
})
export const List = styled("ul")({
  padding: "0 1.5rem",
  margin: "0",
})
export const ListItem = styled("li")({
  fontSize: "0.875rem"
})
