import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { Router } from './Router';
import { Global } from './styles/global';
import { defaultTheme } from './styles/themes/defaultTheme';
import { LanguageProvider } from './context/language';
import { Suspense } from 'react';
import { LoaderOverlay } from './components/LoaderOverlay';
import { ScrollToTop } from './HOC/ScrollToTop';
export const App = () => {
  return (
    <Suspense fallback={<LoaderOverlay />}>
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <LanguageProvider>
            <Router />
          </LanguageProvider>
          <Global />
          <ScrollToTop />
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};
