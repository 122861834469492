import { styled } from "@mui/system";
import { Typography } from "@mui/material"
import { NavLink } from "react-router-dom"

export const Container = styled("div")(({ theme, direction="column"}) => ({
  display: "flex",
  flexDirection: `${direction}`,
  border: "2px solid #3a3a3a",
  borderRadius: "16px",
  overflow: "hidden",
  margin: "0",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}))

export const ContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "24px",
  height: "100%"
})

export const Title = styled(Typography)({
  fontSize: "1.375rem",
  fontWeight: "400",
  lineHeight: "1.5",
  margin: "0 0 1rem 0",
})

export const ContentText = styled(Typography)(({ lines=2 }) => ({
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: `${lines}`,
  WebkitBoxOrient: "vertical",
  margin: "0",
}))

export const Button = styled(NavLink)(({ variant="none" })=>({
  display: "flex",
  padding: "12px 0px",
  cursor: "pointer",
  
  color: "#ff0097",
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: "1.5",
  textDecoration: "none",
  
  border: `${variant === "outlined" ? "1px solid #ff0097" : undefined}`,
  borderRadius:`${variant === "outlined" ? "100px" : undefined}`,
  justifyContent: `${variant === "outlined" ? "center" : undefined}`,
}))