import { styled } from "@mui/system";
import { ImageListItem, ButtonBase } from "@mui/material";
import { GradientText } from "../../../components/GradientText";

export const Container = styled("section")(({ theme }) => ({
  padding: "0 0 5rem 0",
  width: "100%",
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0",
  },
}));

export const StyledImageListItem = styled(ImageListItem)({
  margin: "0 auto",
  position: "relative",
  top: "2.5rem",
});

export const ImagePlaceHolder = styled("div")(({ src, theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  backgroundImage: `url(${src})`,
  width: "40rem",
  height: "40rem",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "46.5rem 47.75rem",

  [theme.breakpoints.down("xl")]: {
    width: "36rem",
    height: "36rem",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "42.5rem 43.75rem",
  },
  [theme.breakpoints.down("lg")]: {
    width: "24rem",
    height: "23rem",
    backgroundSize: "32rem 34rem",
  },
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    width: "100%",
    height: "21rem",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    width: "100%",
    height: "21rem",
  },
}));

export const WrapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  justifyContent: "space-between",
  gap: "5rem",

  [theme.breakpoints.down("lg")]: {
    gap: "4rem",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    fontSize: "2rem",
    gap: "0px",
    p: {
      fontSize: "1.625rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    fontSize: "2rem",
    gap: "0px",
    p: {
      fontSize: "1.625rem",
    },
  },
}));

export const TextContainer = styled("div")(({ theme }) => ({
  width: "53.5rem",
  [theme.breakpoints.down("md")]: {
    width: "100%;",
    marginTop: "2rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%;",
    marginTop: "2rem",
  },
}));

export const GradientContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "5rem",
  width: "36rem",
  left: "-90px",
  [theme.breakpoints.down("xl")]: {
    marginBottom: "2rem",
    left: "-64px",
  },
  [theme.breakpoints.down("lg")]: {
    marginBottom: "1rem",
    left: "-50px",
  },
  [theme.breakpoints.down("md")]: {
    left: "0",
    width: "21rem",
    alignItems: "center",
    marginBottom: "0.75rem",
  },
  [theme.breakpoints.down("sm")]: {
    left: "0",
    width: "21rem",
    alignItems: "center",
    marginBottom: "0.75rem",
  },
}));

export const GradientTitle = styled(GradientText)(({ theme }) => ({
  fontSize: "2.8125rem",
  padding: "0",
  margin: "0",
  width: "100%",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",

  [theme.breakpoints.down("lg")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "2.2rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.8rem",
  },
}));

export const Title = styled("h1")(({ theme }) => ({
  fontSize: "3.75rem",
  padding: "0",
  margin: "0",
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",

  [theme.breakpoints.down("xl")]: {
    fontSize: "2.7rem;",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "2.2rem;",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
    margin: "0",
    marginBottom: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
    margin: "0",
    marginBottom: "1rem",
  },
}));

export const Subtitle = styled("h3")(({ theme }) => ({
  fontSize: "1.5rem",
  padding: "0",
  margin: "0",
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  marginBottom: "1.5rem",

  [theme.breakpoints.down("xl")]: {
    fontSize: "1.625rem",
  },

  [theme.breakpoints.down("md")]: {
    fontSize: "1.7rem",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.625rem",
  },
}));

export const Button = styled(ButtonBase)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "2rem",
  height: "3rem",
  width: "6.18rem",
  border: "solid #FF0097",
  borderRadius: "100px",
  color: "#FFF",
  fontWeight: "bold",
  textDecoration: "none",
  fontSize: "1rem",

  padding: "0",
  "&:hover": {
    color: "#FFF",
  },
  "&.active": {
    color: "#FFF",
    svg: {
      display: "block",
    },
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const NavButton = styled("div")({
  display: "flex",
  outline: "none",
  border: "none",
  background: "none",
  cursor: "pointer",
  color: "#FF0097",
  alignItems: "center",
  svg: { height: "17.41px" },
});

export const WrapperNavigation = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: "1rem",
});

export const GradientRectangle = styled("div")({
  background: "linear-gradient(90deg, #FF0097 0%, #0069E6 100%)",
  width: "128px",
  height: "3px",
  marginTop: "auto",
  marginBottom: "0",
});
