import { styled } from "@mui/system";
import { GradientText } from "../../../components/GradientText";

export const WrapperSection = styled("section")(({ theme }) => ({
  padding: "5rem 5rem",
  "& > p": {
    fontSize: "1.625rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5rem 1.5rem",
    "& > p": {
      fontSize: "1.375rem",
    },
  },
}));

export const WrapperText = styled(GradientText)(({ theme }) => ({
  textAlign: "center",
  fontSize: "2.8125rem",
  marginBottom: "40px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.625rem",
  },
}));
