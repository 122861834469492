import { styled } from "@mui/system"

export const Container = styled("section")(({ theme })=>({
  margin: "0 auto",
  display: "flex",
  padding: "0rem",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "0",
  "span": {
    display: "inline-block",
    fontSize: "3.75rem",
    fontWeight: "500",
    lineHeight: "1.0",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.75rem",
    }
  },
}))
