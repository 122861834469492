import { availableLanguages, userLocale } from "../config/languages";

export const defaultLanguage = () => {
  const defaultLng = "en";
  const savedLanguage = localStorage.getItem("i18nextLng");

  const lng = availableLanguages.includes(userLocale) ? userLocale : defaultLng;

  return savedLanguage ? savedLanguage : lng;
};
