import { Typography } from "@mui/material"
import { styled } from "@mui/system"

export const Container = styled("div")({
  padding: "0 2.5rem",
  borderLeft: "3px solid",
  borderImage: "linear-gradient(180deg, #FF0097 0%, #0069E6 100%) 1 100%",
  minHeight: "5rem"
})

export const Title = styled(Typography)({
  fontSize: "1.375rem",
  fontWeight: "500" 
})

export const Content = styled(Typography)({
  width: "100%"
})