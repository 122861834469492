import { styled } from '@mui/system';
import { GradientText } from '../GradientText';

export const PillarContainer = styled('div')({
  margin: '0 auto',
  maxWidth: '1920px',
});

export const CardWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',
  flex: '1',
  margin: '0 0 160px',
  [theme.breakpoints.down('lg')]: {
    gap: '16px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '80px',
  },
}));

export const SectionTitle = styled(GradientText)({
  width: '11.875rem',
  fontSize: '3.75rem',
});

export const Subtitle = styled('p')({
  fontSize: '1.625rem',
  margin: '0 0 40px',
});

export const SectionFooterText = styled(GradientText)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '2.8125rem',
  lineHeight: '1.5',
  margin: '0 0 160px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.625rem',
    margin: '0 0 80px',
  },
}));
