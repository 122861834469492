import { useState, createContext } from "react";

export const LanguageContext = createContext({
  abbrev: "pt",
  label: "Português",
});

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState({
    abbrev: "pt",
    label: "Português",
  });

  return (
    <LanguageContext.Provider value={[currentLanguage, setCurrentLanguage]}>
      {children}
    </LanguageContext.Provider>
  );
};
