import { styled } from "@mui/system";

export const Item = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",

  height: "205.75px",
  border: "1px solid #3A3A3A",
  img: {
    maxWidth: "160px",
  }
}));
