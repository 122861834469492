import { Container, Media, Content, Title, Text } from './styles';
export const Card = ({ image, title, content }) => {
  return (
    <Container>
      <Media src={image}>
        <Content>
          <Title>{title}</Title>
          <Text>{content}</Text>
        </Content>
      </Media>
    </Container>
  );
};
